import React, { useState } from "react";
import { useStore } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import IconButton from "../buttons/IconButton";
import { FaultDetails } from "./FaultDetails";

export const PanelFaultsTable = ({ faultsList = [] }) => {
	const [expandedPanelDetails, setExpandedPanelDetails] = useState([]);
	const { getState } = useStore();
	const navigate = useNavigate();
	const { projectId, clientId } = useParams();
	const [searchParams] = useSearchParams();

	const panelId = searchParams.get("panelId");

	/**
	 * @param {string} id
	 */
	const toggleDetails = (id) => {
		if (expandedPanelDetails.includes(id)) {
			setExpandedPanelDetails(
				expandedPanelDetails.filter((panel) => panel !== id)
			);
			return;
		}

		setExpandedPanelDetails(expandedPanelDetails.concat(id));
	};

	return (
		<div className="flex flex-col gap-2 mt-4">
			<section className="px-4 py-1 w-full text-xs text-zinc-400">
				<div className="flex items-center justify-between gap-4">
					<div className="basis-20 min-w-[90px]">
						<p>Vznik poruchy</p>
					</div>

					<div className="justify-start basis-36">
						<p>Typ poruchy</p>
					</div>

					<div className="justify-start basis-36">
						<p>Stav</p>
					</div>

					<div className="justify-start basis-32">
						<p>Akce</p>
					</div>
				</div>
			</section>

			{faultsList.map((fault) => (
				<section
					key={fault.id}
					className="pl-2 pr-4 w-full bg-white rounded-lg shadow-sm text-sm font-bold"
				>
					<div className="flex items-center justify-between gap-4">
						<div className="min-w-fit basis-24">
							<IconButton
								className="p-2"
								onClick={() => toggleDetails(fault.id)}
								icon={
									expandedPanelDetails.includes(fault.id)
										? "/images/sidebar_dropdown_selected.svg"
										: "/images/sidebar_dropdown.svg"
								}
								size={10}
							/>

							<span className="ml-1 text-sm">{fault.createdAt}</span>
						</div>

						<div className="flex items-center justify-start basis-40">
							<p>{fault.name}</p>
						</div>

						<div className="flex items-center justify-start basis-40">
							<p
								// TODO: 🚨 Abstract this into a function instead of having it like this like a caveman
								className={`${
									fault.status === "ACTIVE"
										? "text-red-700"
										: fault.status === "SOLVED"
										? "text-green-700"
										: "text-violet-600"
								} `}
							>
								{fault.status === "ACTIVE"
									? "Nevyřešeno"
									: fault.status === "SOLVED"
									? "Vyřešeno"
									: "Sledování poruchy"}
							</p>
						</div>

						<div className="flex items-center gap-2 basis-32">
							<button
								className="py-1 px-4 text-xs rounded-full text-white bg-primary  min-w-fit flex justify-between items-center leading-tight max-w-[100px] transition-all max-h-10 font-bold hover:shadow-lg"
								onClick={() => {
									navigate(
										`/clients/${clientId}/projects/${projectId}/panels/update-fault?panelId=${panelId}&faultId=${fault.id}`
									);
								}}
							>
								Upravit
							</button>
							<button className="p-1 text-xs w-5 rounded-full text-white bg-primary transition-all max-h-10 font-bold hover:shadow-lg ">
								<img
									// Some SVG's are colored orange, this makes sure they show up as white
									className="hue-rotate-180 sepia contrast-200"
									src="/images/sidebar_record_export.svg"
									alt=""
								/>
							</button>
							{getState().auth.me.isAdmin && (
								<button className="p-1 text-xs w-5 rounded-full text-white bg-primary transition-all max-h-10 font-bold hover:shadow-lg ">
									<img
										// Some SVG's are colored orange, this makes sure they show up as white
										className="hue-rotate-180 sepia contrast-200"
										src="/images/sidebar_trash_mini.svg"
										alt=""
									/>
								</button>
							)}
						</div>
					</div>

					{/* Panel Entry Details */}
					{expandedPanelDetails.includes(fault.id) && (
						<div className="mt-1 border-t-2 flex-col border-secondary py-2 flex items-center justify-between">
							<div className="mb-2 w-full grid grid-cols-5 text-xs text-zinc-400">
								<p>Datum</p>
								<p>Technik</p>
								<p>Aktivita</p>
								<p>Poznámka</p>
								<p>Soubory</p>
							</div>

							<FaultDetails faultId={fault.id} />
						</div>
					)}
				</section>
			))}
		</div>
	);
};
