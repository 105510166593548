import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import CircleButton from "../buttons/CircleButton";

const SearchBar = ({ onSearch, value, placeholder, ...otherProps }) => {
	const [searchText, setSearchText] = React.useState("");
	const { t } = useTranslation(namespaces.common);

	const handleSearchChange = (e) => {
		setSearchText(e.target.value);
	};

	useEffect(() => {
		setSearchText(value);
	}, [value]);

	const handleSearch = useCallback(() => {
		if (onSearch) {
			onSearch(searchText);
		}
	}, [onSearch, searchText]);

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	const theme = "bg-white";

	return (
		<div className="flex flex-col gap-[4px]">
			<div className="text-sm font-bold text-700">
				{t("dialogs.search.title")}
			</div>
			<div className="flex items-center relative">
				<input
					onKeyDown={handleKeyDown}
					onChange={handleSearchChange}
					className={`rounded-full px-[14px] h-[35px] text-left flex-grow text-input-500 focus:outline-none focus:shadow-outline ${theme}`}
					type="search"
					placeholder={placeholder || t("dialogs.search.placeholder")}
					value={searchText}
					{...otherProps}
				/>

				<CircleButton
					icon="/images/preview.svg"
					primary
					// disabled={disabled}
					className="w-[29px] h-[29px] absolute right-[3px] top-[3px]"
					iconSize="12px"
					onClick={handleSearch}
				/>
				{value !== "" && value != null && (
					<CircleButton
						icon="/images/sidebar_search_clear.svg"
						// disabled={disabled}
						className="w-[29px] h-[29px] absolute right-[35px] top-[3px]"
						iconSize="16px"
						onClick={() => {
							onSearch("");
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default SearchBar;
