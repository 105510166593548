import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { namespaces } from "../../../consts/i18n";
import useFilterRecords, { getFilters } from "../../../hooks/useFilterRecords";
import FilterItem from "../FilterItem";

function RecordFilter() {
	const { project } = useSelector((state) => state.info);

	const filters = getFilters(project?.allowedRecordTypes || []);

	const { t } = useTranslation(namespaces.viewer);

	const [filterTypes, toggleFilterType] = useFilterRecords();

	const handleToggleFilter = useCallback(
		(filterItem) => {
			toggleFilterType(filterItem);
		},
		[toggleFilterType]
	);

	// useEffect(() => {
	//   console.log(filterTypes);
	//   console.log(filters);
	// }, [filterTypes]);

	return (
		<div className="flex flex-row flex-wrap gap-2 my-[30px]">
			{filters.map((filter) => (
				<FilterItem
					key={filter}
					checked={filterTypes.includes(filter)}
					onToggle={() => handleToggleFilter(filter)}
					label={t(`sidebar.filters.${filter}`)}
				/>
			))}
		</div>
	);
}

export default RecordFilter;
