import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import uploadApi from "../../api/uploadApi";
import { namespaces } from "../../consts/i18n";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogWrapper from "./DialogWrapper";

const PreviewFileDialog = ({ isOpen, onClose, file, isLocalFile = false }) => {
	const { t } = useTranslation(namespaces.pages);

	const [previewFile, setPreviewFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [downloadProgress, setDownloadProgress] = useState(0);

	const handleCancel = (e) => {
		e.preventDefault();
		onClose();
	};

	const fetchBlobUrl = useCallback(async () => {
		const url = await uploadApi.fetchPreviewBlobUrl(file.id, {
			onDownloadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				setDownloadProgress(percentCompleted);
			},
		});

		return url;
	}, [file]);

	useEffect(() => {
		if (!file) return;

		setPreviewFile(null);
		setDownloadProgress(0);

		setIsLoading(true);

		if (!isLocalFile) {
			fetchBlobUrl()
				.then((url) => {
					setPreviewFile(url);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			const objectUrl = URL.createObjectURL(file);
			setPreviewFile(objectUrl);
			setIsLoading(false);
		}
	}, [file, fetchBlobUrl, isLocalFile]);

	return isOpen ? (
		<DialogWrapper onClick={handleCancel}>
			<div
				className="relative bg-white rounded-xl w-[590px] px-[53px] pt-[65px] pb-[53px]"
				onClick={(e) => e.stopPropagation()}
			>
				<CloseButton onClick={handleCancel}></CloseButton>
				<div className="flex flex-col gap-y-[30px] items-left justify-left">
					<div>
						<h3 className="text-sm font-bold text-center px-[26px]">
							{isLocalFile ? file.name : file?.originalName}
						</h3>
					</div>

					<div className="w-full flex justify-center">
						{isLoading ? (
							<div className="w-full h-[300px] bg-gray-200 animate-pulse">
								<div className="w-full h-full flex items-center justify-center">
									<div className="w-[200px] h-[10px] bg-gray-300 rounded-full">
										<div
											className="h-full bg-primary rounded-full"
											style={{ width: `${downloadProgress}%` }}
										/>
									</div>
								</div>
							</div>
						) : null}
						{previewFile && (
							<img
								src={previewFile}
								alt="preview"
								className="max-w-[500px] max-h-[500px]"
							/>
						)}
					</div>

					<div
						className={`flex items-center justify-center gap-x-[20px] w-full`}
					>
						<div>
							<TextButton
								className={"h-[56px]"}
								label={t("previewFileDialog.ok")}
								onClick={handleCancel}
							/>
						</div>
					</div>
				</div>
			</div>
		</DialogWrapper>
	) : null;
};

export default PreviewFileDialog;
