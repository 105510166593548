import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	sortBy: "Od nejnovějšího", // most recent first
	searchText: "",
	filterBy: "",
};

export const panelSlice = createSlice({
	name: "panel",
	initialState,
	reducers: {
		setPanelSortBy: (state, action) => {
			state.sortBy = action.payload;
		},
		setPanelSearchText: (state, action) => {
			state.searchText = action.payload;
		},
		setPanelFilterBy: (state, action) => {
			state.filterBy = action.payload;
		},
	},
});

export const { setPanelSortBy, setPanelSearchText, setPanelFilterBy } =
	panelSlice.actions;

export default panelSlice.reducer;
