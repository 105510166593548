import React, { useCallback, useEffect } from "react";
import DialogWrapper from "./DialogWrapper";
import { namespaces } from "../../consts/i18n";
import { useTranslation } from "react-i18next";
import DialogTitle from "./DialogTitle";
import TextButton from "../buttons/TextButton";
import CloseButton from "../buttons/CloseButton";
import FVEForm from "../forms/FVEForm";
import GraveYardForm from "../forms/GraveYardForm";
import ParksAndGardensForm from "../forms/ParksAndGardensForm";
import FacilityForm from "../forms/FacilityForm";
import GeneralForm from "../forms/GeneralForm";
import TextInput, { TextInputSmall } from "../inputs/TextInput";
import { RecordType } from "../../api/consts";
import { useSelector, useDispatch } from "react-redux";
import {
  useUpdateRecordMutation,
  useGetRecordQuery,
  managementApi,
} from "../../api/managementApi";
import useUploadRecordFiles from "../../hooks/useUploadRecordFiles";
import LoadingBar from "../LoadingBar";
import QRCodeLinkDialog from "./QRCodeLinkDialog";
import {
  getMapyCzUrlFromKrovak,
  getCenterPoint,
  flattenPoints,
} from "../../utils/geo";
import { RecordToPrint } from "../viewer/RecordToPrint";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import useAuthInfo from "../../hooks/useAuthInfo";

const useQRCodeLink = (point) => {
  const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = React.useState(false);
  const [qrcodeLinkData, setQRCodeLinkData] = React.useState(null);

  const onQRCodeClick = useCallback(() => {
    if (!point) return;
    const url = getMapyCzUrlFromKrovak(point[0], point[1], 18);
    setQRCodeLinkData(url);
    setIsQRCodeDialogOpen(true);
  }, [point]);

  const onQRCodeDialogClose = useCallback(() => {
    setIsQRCodeDialogOpen(false);
  }, []);

  return {
    isQRCodeDialogOpen,
    qrcodeLinkData,
    onQRCodeClick,
    onQRCodeDialogClose,
  };
};

const EditRecordDialog = ({ isOpen, onClose, recordId }) => {
  const { mode } = useSelector((state) => state.measures);
  const dispatch = useDispatch();
  const { t } = useTranslation(namespaces.viewer);
  const [formData, _setFormData] = React.useState({});
  const [changed, setChanged] = React.useState(false);
  const [name, setName] = React.useState("");
  const [issues, setIssues] = React.useState([]);

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();
  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

  // get record data lazily
  const { data } = useGetRecordQuery(
    { id: recordId },
    {
      skip: !isOpen,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    isQRCodeDialogOpen,
    qrcodeLinkData,
    onQRCodeClick,
    onQRCodeDialogClose,
  } = useQRCodeLink(getCenterPoint(flattenPoints(data?.points)));

  const [updateRecord, { isLoading, isSuccess, isError, error }] =
    useUpdateRecordMutation();

  const {
    uploadFiles,
    isUploading,
    uploadProgress,
    uploadError,
    uploadAbortController,
    clearUploadError,
  } = useUploadRecordFiles();

  const handleCancel = useCallback(() => {
    // cancel upload if in progress
    if (isUploading) {
      uploadAbortController.abort();
    }

    onClose();
  }, [isUploading, uploadAbortController, onClose]);

  const setFormData = (data) => {
    _setFormData(data);
    setChanged(true);
  };

  const handleSubmit = useCallback(async () => {
    // omit nore from formData
    const { note, ...rest } = formData;

    if (formData.files && formData.files.length > 0) {
      const res = await uploadFiles(recordId, formData.files);
      if (!res) {
        return;
      }
    }

    try {
      await updateRecord({
        id: recordId,
        name: name,
        note: note,
        extra: rest,
      }).unwrap();
    } catch (err) {
      if (err.data?.issues) {
        setIssues(err.data.issues);
        return;
      }
      throw err;
    }

    onClose();
  }, [data, formData, name, updateRecord, dispatch, mode, onClose, recordId]);

  useEffect(() => {
    if (!isOpen) return;

    if (data) {
      setName(data.name);

      setFormData({ ...data.extra, photos: data.photos, note: data.note });
    } else {
      setName("");
      setFormData({});
    }
    clearUploadError();
    setChanged(false);
    onQRCodeDialogClose();

  }, [isOpen, data, clearUploadError, onQRCodeDialogClose]);

  const handleChangeName = (value) => {
    setName(value);
    setChanged(true);
  };

  const canSave = changed;

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[671px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel} />
        <div className="flex flex-col gap-[30px] items-center">
          <DialogTitle title={t("editRecordDialog.title")}></DialogTitle>

          <div className="w-[520px] flex flex-row justify-between gap-[40px]">
            <div className="flex flex-row justify-between items-end w-[313px]">
              <TextInput
                // disabled={!isAdmin}
                inputClassName="h-[38px] w-[313px]"
                label={t("editRecordDialog.name")}
                value={name}
                onChange={handleChangeName}
                extraError={getErrorForPath(issues, ["name"])}
              ></TextInput>
            </div>
            <div className="w-full"></div>

            {/* <div className="flex flex-row gap-[24px] justify-between gap-[40px]">
            <div className="w-[313px]">
              <TextInputSmall
                label={t("editRecordDialog.name")}
                value={name}
                onChange={handleChangeName}
              ></TextInputSmall>
            </div>
            <div className="w-full"> */}

            {/* <div className="w-full">
              <TextButton
                onClick={onQRCodeClick}
                className={"h-[56px]"}
                label={t("editRecordDialog.qrCodeLink")}
              />
            </div> */}
          </div>

          {data && (
            <div
              className="flex flex-col gap-[24px] 
              overflow-y-auto overflow-x-hidden max-h-[500px]
              bg-bg1 rounded-[30px] py-[30px] w-[524px] px-[40px]
          "
            >
              {data.type === RecordType.FAULT && formData && (
                <FVEForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                  // disabled={!isAdmin}
                  disabled={!canEdit}
                />
              )}

              {data.type === RecordType.CEMETERY && formData && (
                <GraveYardForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}

              {data.type === RecordType.PARK && formData && (
                <ParksAndGardensForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}

              {data.type === RecordType.AREA && formData && (
                <FacilityForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}

              {data.type === RecordType.GENERIC && formData && (
                <GeneralForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}
            </div>
          )}

          <div className="px-[80px] w-full">
            {isUploading && (
              <LoadingBar progress={uploadProgress * 100}></LoadingBar>
            )}
          </div>

          {uploadError && (
            <div className="text-red-500 text-center text-xs">
              {uploadError}
            </div>
          )}

          <div className="flex flex-row justify-between gap-[40px] mt-[20px]">
            <TextButton
              isLoading={isLoading}
              className={"h-[56px]"}
              label={t("editRecordDialog.cancel")}
              onClick={handleCancel}
              secondary={true}
            />
            <TextButton
              disabled={!canSave || !canEdit}
              isLoading={isLoading}
              className={"h-[56px]"}
              label={t("editRecordDialog.edit")}
              onClick={handleSubmit}
            />
          </div>
        </div>

      </div>
      <QRCodeLinkDialog
        isOpen={isQRCodeDialogOpen}
        link={qrcodeLinkData}
        onClose={onQRCodeDialogClose}
      ></QRCodeLinkDialog>
    </DialogWrapper>
  ) : null;
};

export default EditRecordDialog;
