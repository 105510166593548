import React from "react";

const IconButton = ({
	icon,
	onClick,
	disabled,
	size,
	className = "",
	...otherProps
}) => {
	const sizeString = size ? `w-[${size}px] h-[${size}px]` : "w-[28px] h-[28px]";

	return (
		<button
			className={`rounded-full hover:bg-primaryLight disabled:opacity-50 disabled:cursor-not-allowed active:bg-primaryDark transition-colors duration-300 ${className}`}
			disabled={disabled || false}
			onClick={onClick}
			{...otherProps}
		>
			<img
				src={icon}
				className={sizeString}
				width={size}
				height={size}
				alt="icon"
			/>
		</button>
	);
};

export default IconButton;
