import React, {  useEffect } from "react";
import DialogWrapper from "./DialogWrapper";
import { namespaces } from "../../consts/i18n";
import { useTranslation } from "react-i18next";
import DialogTitle from "./DialogTitle";
import TextInput from "../inputs/TextInput";
import TextButton from "../buttons/TextButton";
import InputLabel from "../inputs/InputLabel";
import { DimensionType } from "../../api/dimensionType";
import CloseButton from "../buttons/CloseButton";
import { measureNameMaxLength, measureNamePattern } from "../../utils/fsUtils";
import { useSelector } from "react-redux";

const MeasureOptions = [
  {
    label: "createMeasureDialog.options.volume",
    value: "volume",
  },
  {
    label: "createMeasureDialog.options.distance",
    value: "distance",
  },
  {
    label: "createMeasureDialog.options.area",
    value: "area",
  },
  {
    label: "createMeasureDialog.options.point",
    value: "point",
  }
];

function OptionItem({ label, onToggle, checked }) {
  const handleClick = () => {
    onToggle(!checked);
  };

  return (
    <button
      className={
        (checked
          ? " bg-primary text-white hover:bg-primary hover:text-white"
          : " bg-bg1 text-black hover:bg-[#EFEFEF] hover:text-black") +
        " rounded-full p-1 px-[25px] text-700 text-[14px] font-bold h-[49px]  "
      }
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

const CreateMeasureDialog = ({ isOpen, onCreate, onClose, dimensionType }) => {
  const handleSubmit = () => {
    if (name && selectedOption) {
      onCreate(name, selectedOption);
    }
  };

  const measureMode = useSelector((state) => state.measures.mode);

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setName("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (measureMode === DimensionType.D2) {
      setSelectedOption("distance");
    } else {
      setSelectedOption("volume");
    }
  }, [measureMode]);

  const [selectedOption, setSelectedOption] = React.useState("distance");
  const [name, setName] = React.useState("");
  const [nameValid, setNameValid] = React.useState(true);

  const { t } = useTranslation(namespaces.viewer);

  const handleOptionSelect = (option) => (e) => {
    setSelectedOption(option.value);
  };

  const optionsList = MeasureOptions.filter(
    dimensionType === DimensionType.D2
      ? (option) => option.value !== "volume"
      : (option) => option.value !== "area"
  );

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel} />
        <div className="flex flex-col gap-[30px]">
          <DialogTitle title={t("createMeasureDialog.title")}></DialogTitle>

          <TextInput
            onChange={setName}
            label={t("createMeasureDialog.measureName")}
            pattern={measureNamePattern}
            maxLength={measureNameMaxLength}
            onValidityChange={(valid) => {
              setNameValid(valid);
            }}
          />

          <div>
            <InputLabel>{t("createMeasureDialog.measureType")}</InputLabel>
            <div className="flex flex-row gap-[20px]">
              {optionsList.map((option) => (
                <OptionItem
                  key={option.value}
                  label={t(option.label)}
                  onToggle={handleOptionSelect(option)}
                  checked={selectedOption === option.value}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-row justify-between gap-[64px] mt-[20px]">
            <TextButton
              disabled={!name || !selectedOption || !nameValid}
              className={"h-[56px]"}
              label={t("createMeasureDialog.create")}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default CreateMeasureDialog;
