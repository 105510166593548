import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_URL } from "../config";
import { getToken } from "../providers/authProvider";

export const faultApi = createApi({
	reducerPath: "faultApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API_URL,
		mode: "cors",
		prepareHeaders: (headers) => {
			const token = getToken();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		// TODO: move to management api.js

		getFaultDetails: builder.query({
			query: ({ faultId }) => ({
				url: `fault/${faultId}`,
				method: "GET",

				responseHandler: (res) => {
					return res.json().then((data) => {
						return data.data;
					});
				},
			}),
			providesTags: ["Faults"],
		}),
		createFault: builder.mutation({
			query: ({ solarPanelId, name, note, createdAt, technicianName }) => ({
				url: "fault",
				method: "POST",
				body: {
					solarPanelId,
					note,
					type: "SOLAR_PANEL",
					name,
					createdAt,
					technicianName,
				},
			}),
			invalidatesTags: [{ type: "Record", id: "LIST" }],
		}),
		updateFault: builder.mutation({
			query: ({
				faultId,
				name,
				status,
				type,
				note,
				technicianName,
				dateTime,
			}) => ({
				url: `fault/${faultId}`,
				method: "PATCH",
				body: {
					name,
					status,
					type,
					note,
					technicianName,
					dateTime,
				},
			}),
			invalidatesTags: ["Faults"],
		}),
		deleteFault: builder.mutation({
			query: ({ faultId }) => ({
				url: `fault/${faultId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Faults"],
		}),
	}),
});

export const {
	useCreateFaultMutation,
	useGetFaultDetailsQuery,
	useUpdateFaultMutation,
	useDeleteFaultMutation,
} = faultApi;
