import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DimensionType } from "../../../api/dimensionType";
import {
	useCreateMeasureMutation,
	useDeleteMeasureMutation,
	useGetProjectStateQuery,
} from "../../../api/managementApi";
import { namespaces } from "../../../consts/i18n";
import useSearchMeasures from "../../../hooks/useSearchMeasures";
import useSearchPanels from "../../../hooks/useSearchPanels";
import useSearchRecords from "../../../hooks/useSearchRecords";
import useViewerDisplayMode from "../../../hooks/useViewerDisplayMode";
import { getUniqueName } from "../../../redux/measures/measuresReducer";
import {
	addNonStoredMeasure,
	removeNonStoredMeasure,
} from "../../../redux/measures2/measures2Reducer";
import { addMessage } from "../../../redux/messages/messagesReducer";
import { toolModeType } from "../../../redux/pointCloudViewer/pointCloudViewerReducer";
import { DisplayType } from "../../../redux/viewer/viewerReducer";
import CircleButton from "../../buttons/CircleButton";
import CreateMeasureDialog from "../../dialogs/CreateMeasureDialog";
import CreateRecordDialog from "../../dialogs/CreateRecordDialog";
import SearchBar from "../../inputs/SearchBar";
import DisplayTypeTabs from "./DisplayTypeTabs";
import MeasureFilter from "./MeasureFilter";
import MeasureList from "./MeasureList";
import { PanelFilter } from "./PanelsList/PanelFilter";
import { PanelsList } from "./PanelsList/PanelsList";
import RecordFilter from "./RecordFilter";
import RecordList from "./RecordList";

/**
 * @param {{onCreateMeasure: Function, dimensionType: DimensionType, setToolMode: Function}} props
 */
const Sidebar = ({ onCreateMeasure, dimensionType, setToolMode }) => {
	const dispatch = useDispatch();
	const nonStoredPanels = useSelector((state) => state.measures2.nonStored);
	const { projectId, stateId } = useParams();

	const { data: projectState } = useGetProjectStateQuery({
		projectId,
		id: stateId,
	});

	const [displayMode] = useViewerDisplayMode();

	const [isOpenCreateMeasureDialog, setIsOpenCreateMeasureDialog] =
		React.useState(false);

	const handleCreateMeasureClick = (name, type = toolModeType.DISTANCE) => {
		setIsOpenCreateMeasureDialog(false);
		onCreateMeasure(name, type);
	};

	const [isCreateRecordDialogOpen, setIsCreateRecordDialogOpen] =
		React.useState(false);
	const [createRecordInitialData, setCreateRecordInitialData] =
		React.useState(null);

	const { project } = useSelector((state) => state.info);

	const [createMeasure /* { isLoading: createMeasureLoading }*/] =
		useCreateMeasureMutation();
	const [deleteMeasure /*{ isLoading: deleteMeasureLoading }*/] =
		useDeleteMeasureMutation();

	const { t } = useTranslation(namespaces.viewer);

	const handleStoreClick = useCallback(
		({ measure, toBeStored }) => {
			if (dimensionType === DimensionType.D2 && toBeStored) {
				setCreateRecordInitialData({ measure });
				setIsCreateRecordDialogOpen(true);
				return;
			}

			if (toBeStored) {
				createMeasure({
					projectId: projectId,
					dimension: dimensionType,
					name: measure.name,
					type: measure.type,
					data: measure.points,
				})
					.unwrap()
					.then((res) => {
						dispatch(removeNonStoredMeasure(measure.id));
					});
			} else {
				deleteMeasure({ id: measure.dbId }).then(() => {
					console.log(measure);
					dispatch(addNonStoredMeasure(measure));
				});
			}
		},
		[dimensionType]
	);

	const [searchFold, setSearchFold] = React.useState(false);

	const handleToggleFoldSearch = () => {
		setSearchFold(!searchFold);
	};

	const [measureSearchText, setMeasureSearchText] = useSearchMeasures();
	const [recordsSearchText, setRecordsSearchText] = useSearchRecords();
	const { panelsSearchText, setPanelsSearchText } = useSearchPanels();

	return (
		<div className="max-h-screen h-screen flex flex-col overflow-y-hidden">
			<div className="h-[54px] max-h-[54px]">
				<DisplayTypeTabs dimensionType={dimensionType} />
			</div>
			<div
				className="pt-[32px] pl-[40px] pr-[20px] flex flex-col grow"
				style={{ height: "calc(100vh - 54px)" }}
			>
				<div className="flex flex-row justify-between mb-[10px]">
					<div className="max-w-[400px] w-full">
						<div className="flex flex-row justify-between">
							<h3 className="text-3xl font-bold text-700 m-0">
								{t("sidebar.title")} {project?.name}
							</h3>

							{displayMode === DisplayType.SOLAR_PANELS && (
								<>
									<button
										onClick={() => {
											dispatch(
												addMessage({
													type: "success",
													// TODO: 🚨 add to translations
													text: "Kliknutím na mapu vytvoříte nový solární panel",
												})
											);
											onCreateMeasure(
												getUniqueName("neuložený panel", nonStoredPanels),
												toolModeType.AREA
											);
										}}
										className="rounded-full min-w-fit text-sm flex justify-between items-center leading-tight max-w-[150px] max-h-10 bg-primary text-white font-bold px-4 hover:bg-opacity-90"
									>
										<img
											className={"pointer-events-none inline w-3 h-3"}
											src="/images/viewer_measurecontrol_open.svg"
											alt=""
											width={12}
											height={12}
										/>
										{/* // TODO: 🚨 add to translations */}
										<span className="ml-2">Nová panel</span>
									</button>
								</>
							)}

							{displayMode !== DisplayType.SOLAR_PANELS && (
								<CircleButton
									icon="/images/sidebar_fold.svg"
									primary
									// disabled={disabled}
									className={`w-[29px] h-[29px] transform  ${
										searchFold ? "rotate-[180deg]" : "rotate-0"
									} animate-transition transition-all
                `}
									iconSize="12px"
									onClick={handleToggleFoldSearch}
								/>
							)}
						</div>
						<span className="text-sm font-bold text-700 m-0">
							{projectState?.name} - {t(`sidebar.view.${dimensionType}`)}
						</span>
					</div>
					{/* <CircleButton
          primary
          className={"w-[43px] h-[43px]"}
          icon={"/images/sidebar_add.svg"}
          onClick={() => setIsOpenCreateMeasureDialog(true)}
        /> */}
				</div>
				<div
					className={`transition-all ease-in-out ${
						searchFold
							? "duration-200 overflow-hidden"
							: "duration-300 delay-150"
					} ${searchFold ? "max-h-0" : "max-h-[1000px]"}`}
				>
					<SearchBar
						onSearch={(searchText) => {
							if (displayMode === DisplayType.MEASURES) {
								setMeasureSearchText(searchText);
								return;
							}

							if (displayMode === DisplayType.RECORDS) {
								setRecordsSearchText(searchText);
								return;
							}

							if (displayMode === DisplayType.SOLAR_PANELS) {
								setPanelsSearchText(searchText);
								return;
							}
						}}
						value={
							displayMode === DisplayType.MEASURES
								? measureSearchText
								: recordsSearchText
						}
					/>

					{displayMode === DisplayType.MEASURES && (
						<MeasureFilter dimensionType={dimensionType} />
					)}
					{displayMode === DisplayType.RECORDS && <RecordFilter />}
					{displayMode === DisplayType.SOLAR_PANELS && <PanelFilter />}
				</div>
				<div className="grow overflow-y-auto pr-[4px]">
					{displayMode === DisplayType.MEASURES && (
						<MeasureList
							filter={(measure) => {
								return true;
								// if (checkedFilters.length === 0) return true;
								// // if satisfy all filters return true
								// return checkedFilters.every((filter) => {
								//   switch (filter) {
								//     case "saved":
								//       return measure.toBeStored;
								//     case "distance":
								//       return measure.type === toolModeType.DISTANCE;
								//     case "area":
								//       return measure.type === toolModeType.AREA;
								//     case "volume":
								//       return measure.type === toolModeType.VOLUME;
								//     default:
								//       return false;
								//   }
								// });
							}}
							onStoreClick={handleStoreClick}
						/>
					)}

					{displayMode === DisplayType.RECORDS && <RecordList />}
					{displayMode === DisplayType.SOLAR_PANELS && (
						<PanelsList
							searchText={panelsSearchText}
							dimension={dimensionType}
						/>
					)}
				</div>
				<CreateMeasureDialog
					dimensionType={dimensionType}
					isOpen={isOpenCreateMeasureDialog}
					onClose={() => setIsOpenCreateMeasureDialog(false)}
					onCreate={handleCreateMeasureClick}
				/>
				<CreateRecordDialog
					project={project}
					isOpen={isCreateRecordDialogOpen}
					initialData={createRecordInitialData}
					onClose={() => setIsCreateRecordDialogOpen(false)}
				/>
			</div>
		</div>
	);
};

export default Sidebar;
