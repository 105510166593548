import { useState, useEffect } from "react";

const useDeviceDetector = () => {
	const [isMobile, setIsMobile] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		let hasTouchScreen = false;
		if ("maxTouchPoints" in navigator) {
			// TODO: 🐛 - a laptop with a touchscreen will be denied access!
			hasTouchScreen = navigator.maxTouchPoints > 10;
		} else if ("msMaxTouchPoints" in navigator) {
			hasTouchScreen = navigator.msMaxTouchPoints > 0;
		} else {
			const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
			if (mQ && mQ.media === "(pointer:coarse)") {
				hasTouchScreen = !!mQ.matches;
			} else if ("orientation" in window) {
				hasTouchScreen = true; // deprecated, but good fallback
			} else {
				// Only as a last resort, fall back to user agent sniffing
				var UA = navigator.userAgent;
				hasTouchScreen =
					/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
					/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
			}
		}
		if (hasTouchScreen) {
			setIsMobile(true);
			setIsDesktop(false);
		} else {
			setIsMobile(false);
			setIsDesktop(true);
		}
	}, []);

	return { isMobile, isDesktop };
};

export default useDeviceDetector;
