import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DimensionType } from "../api/dimensionType";
import { DisplayType, setDisplayMode } from "../redux/viewer/viewerReducer";
import useAuth from "./useAuth";

const useViewerDisplayMode = () => {
	const dispatch = useDispatch();
	const { isTechnician } = useAuth();

	/**
	 * @typedef {object} SelectedDisplayState
	 * @property {DisplayType[keyof typeof DisplayType]} activeDisplayMode
	 */
	/** @type {SelectedDisplayState} */
	const { activeDisplayMode } = useSelector((state) => state.viewer);

	const measureMode = useSelector((state) => state.measures.mode);

	useEffect(() => {
		// in 3d mode, switch to measures display mode
		if (measureMode === DimensionType.D3) {
			if (!isTechnician) {
				dispatch(setDisplayMode(DisplayType.MEASURES));
				return;
			}

			dispatch(setDisplayMode(DisplayType.SOLAR_PANELS));
		}

		if (measureMode === DimensionType.D2) {
			if (!isTechnician) {
				// default state is MEASURES in the store
				return;
			}
			dispatch(setDisplayMode(DisplayType.SOLAR_PANELS));
		}
	}, [isTechnician, measureMode]);

	/**
	 * @param {DisplayType[keyof typeof DisplayType]} mode
	 */
	const _setDisplayMode = (mode) => {
		dispatch(setDisplayMode(mode));
	};

	return [activeDisplayMode, _setDisplayMode];
};

export default useViewerDisplayMode;
