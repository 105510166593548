import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { toReadableDate } from "../../utils/date";
import { convertBytesToFileSizeString } from "../../utils/stringUtils";
import CircleButton from "../buttons/CircleButton";
import Card from "./Card";

/**
 * @param { Function } cb - callback function to execute after stopPropagation
 */
const withStopPropagation =
	(cb) =>
	/**
	 * @param {Event} e
	 * @param  {...any} args
	 * @returns
	 */
	(e, ...args) => {
		e.stopPropagation();
		return cb(...args);
	};

const computeSizeOfState = (state) => {
	let size = 0;

	if (state?.files) {
		state.files.forEach((file) => {
			if ([".las", ".tif", ".tfw"].includes(file.extension?.toLowerCase()))
				size += file.size;
		});
	}

	return size;
};

function MeasureCard({
	measure,
	onClick,
	onEdit,
	onShare,
	onShareTechnicianLink,
	onDelete,
	onFilesClick,
	...otherProps
}) {
	const { t } = useTranslation(namespaces.pages);

	const handleClick = (e) => {
		e.preventDefault();
		onClick(measure);
	};

	const tags = [
		`${t("projectPage.measurement")} • ${convertBytesToFileSizeString(
			computeSizeOfState(measure)
		)}`,
	];

	return (
		<Card
			title={measure.name}
			tags={tags}
			pretitle={t("projectPage.measurement")}
			subtitle={`vytvořeno ${toReadableDate(measure.createdAt)}`}
			onClick={handleClick}
			actions={[
				measure.files?.length > 0 && (
					<CircleButton
						icon="/images/files.svg"
						onClick={withStopPropagation(() => onFilesClick(measure))}
					/>
				),
				onShare && (
					<CircleButton
						icon="/images/link.svg"
						onClick={withStopPropagation(() => onShare(measure))}
					/>
				),
				onShareTechnicianLink && (
					<CircleButton
						className="hue-rotate-180"
						icon="/images/link.svg"
						title="Create Technician Link"
						onClick={withStopPropagation(() => onShareTechnicianLink(measure))}
					/>
				),
				onEdit && (
					<CircleButton
						icon="/images/edit.svg"
						onClick={withStopPropagation(() => onEdit(measure))}
					/>
				),
				onDelete && (
					<CircleButton
						icon="/images/trash.svg"
						onClick={withStopPropagation(() => onDelete(measure))}
					/>
				),
			]}
			{...otherProps}
		/>
	);
}

export default MeasureCard;
