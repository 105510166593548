import { useEffect, useState } from "react";
import {
  useLazySearchRecordsQuery,
  useLazyListRecordsQuery,
} from "../api/managementApi";
import { useSelector } from "react-redux";
import { DimensionType } from "../api/dimensionType";

const useGetProjectRecords = ({ projectId, dimension }) => {
  const [
    getRecords,
    {
      data: getResult,
    },
  ] = useLazyListRecordsQuery();

  const [searchRecords, { data: searchResult }] =
    useLazySearchRecordsQuery();

  const serverRecords = getResult?.records;
  const searchedRecords = searchResult?.records;

  const extra1 = getResult?.extra;
  const extra2 = searchResult?.extra;

  const nonStoredRecords = useSelector((state) => state.records.nonStored);

  const searchText = useSelector((state) => state.records.search.text);
  const areaTypeFilter = useSelector((state) => state.records.filter.areaTypes);

  const [allRecords, setAllRecords] = useState([]);
  const isSearching = !!searchText || areaTypeFilter.length > 0;

  useEffect(() => {
    if (!projectId || !dimension) return;
    
    if (dimension !== DimensionType.D2) return; // only 2D for now
    
    if (isSearching) {
      searchRecords({
        projectId,
        dimension,
        searchString: searchText,
        areaRecordType: areaTypeFilter,
      });
    } else {
      getRecords({
        projectId,
        dimension,
      });
    }
  }, [searchText, projectId, dimension, areaTypeFilter, isSearching]);

  useEffect(() => {
    if (!serverRecords) return;

    const allRec = [
      ...serverRecords.filter((r) =>
        nonStoredRecords.find((nr) => nr.id === r.id) ? false : true
      ),
      ...nonStoredRecords,
    ];

    setAllRecords(allRec);
  }, [serverRecords, nonStoredRecords]);


  return {
    records:
      (isSearching
        ? searchedRecords
        : allRecords) || [],
    extra: isSearching ? extra2 : extra1,
  };
};

export default useGetProjectRecords;
