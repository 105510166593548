import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RecordType } from "../../../../api/consts";
import {
	useDeleteRecordMutation,
	useGetSolarPanelFaultDetailsQuery,
	useLazyGetRecordQuery,
	useListPanelsQuery,
	useUpdateRecordMutation,
} from "../../../../api/managementApi";
import useAuthInfo from "../../../../hooks/useAuthInfo";
import {
	deselectMeasure,
	hideMeasure,
	removeNonStoredMeasure,
	selectMeasure,
	showMeasure,
} from "../../../../redux/measures2/measures2Reducer";
import { addMessage } from "../../../../redux/messages/messagesReducer";
import {
	addToEditableRecords,
	clearRecordChanges,
	removeFromEditableRecords,
} from "../../../../redux/records/recordsReducer";
import { poorMansFuzzySearch } from "../../../../utils/fuzzySearch";
import {
	computeArea,
	flattenPoints,
	getCenterPoint,
	getMapyCzUrlFromKrovak,
	isValidPolygon,
} from "../../../../utils/geo";
import { getCoordinateString } from "../../../../utils/stringUtils";

/**
 * @param {Array} panelsList
 */
function filterAndSort({ panelsList = [], filterBy, sortBy, searchText = "" }) {
	const result = panelsList
		.filter((p) => {
			if (filterBy === "Aktivní") {
				return p.extra.faults.filter((f) => f.status === "ACTIVE").length > 0;
			}
			if (filterBy === "Sledování") {
				return (
					p.extra.faults.filter((f) => f.status === "OBSERVATION").length > 0
				);
			}
			if (filterBy === "Vyřešeno") {
				return p.extra.faults.filter((f) => f.status === "SOLVED").length > 0;
			}
			// if empty string
			return true;
		})
		.filter((p) => {
			return poorMansFuzzySearch(searchText, p.name);
		})
		.sort((a, b) => {
			if (sortBy === "Abecedně A–Z") {
				return a.name.localeCompare(b.name);
			}

			if (sortBy === "Abecedně Z–A") {
				return b.name.localeCompare(a.name);
			}

			if (sortBy === "Od nejnovějšího") {
				return new Date(b.createdAt) - new Date(a.createdAt);
			}
			return new Date(a.createdAt) - new Date(b.createdAt);
		});

	return result;
}

/**
 * @param {{searchText: string, dimension: string} } param0
 */
export function usePanels({ searchText, dimension }) {
	const dispatch = useDispatch();

	const { clientId, projectId, stateId } = useParams();

	const [panelIds, setPanelIds] = useState([]);

	const unsavedMeasures = useSelector((state) => state.measures2.nonStored);
	const filterBy = useSelector((state) => state.panel.filterBy);
	const sortBy = useSelector((state) => state.panel.sortBy);
	const { hidden } = useSelector((state) => state.measures2);

	const { isDemoUser, isStateLinkLevel } = useAuthInfo();
	const canEdit = !isDemoUser && !isStateLinkLevel;

	const [deleteRecord] = useDeleteRecordMutation();

	const {
		data: panels,
		isLoading,
		refetch,
	} = useListPanelsQuery({ projectId }, { refetchOnMountOrArgChange: true });

	const { data: panelsWithFaultsData, isLoading: isLoadingFaults } =
		useGetSolarPanelFaultDetailsQuery(
			{ solarPanelIds: panelIds },
			{ refetchOnMountOrArgChange: true }
		);

	// get ids from panels
	useEffect(() => {
		if (!panels?.length) return;
		setPanelIds(panels.map((panel) => panel.id));
	}, [panels]);

	const [filteredPanelsList, setFilteredPanelsList] = useState([]);
	useEffect(() => {
		if (!panelsWithFaultsData?.length && !unsavedMeasures.length) return;

		if (panelsWithFaultsData?.length && !unsavedMeasures.length) {
			setFilteredPanelsList(
				filterAndSort({
					panelsList: panelsWithFaultsData,
					filterBy,
					sortBy,
					searchText,
				})
			);
			return;
		}

		const unsavedPanels = unsavedMeasures.filter(
			(measure) => measure.isSolarPanel
		);

		if (unsavedPanels.length && !panelsWithFaultsData?.length) {
			setFilteredPanelsList(
				filterAndSort({
					panelsList: unsavedPanels,
					filterBy,
					sortBy,
					searchText,
				})
			);
			return;
		}
		setFilteredPanelsList(
			filterAndSort({
				panelsList: [...panelsWithFaultsData, ...unsavedPanels],
				filterBy,
				sortBy,
				searchText,
			})
		);
	}, [unsavedMeasures, panelsWithFaultsData]);

	useEffect(() => {
		setFilteredPanelsList(
			filterAndSort({
				panelsList: panelsWithFaultsData,
				filterBy,
				sortBy,
				searchText,
			})
		);
	}, [panelsWithFaultsData, filterBy, sortBy, searchText, panelIds]);

	const [expandedPanelDetails, setExpandedPanelDetails] = useState([]);
	/**
	 * @param {string} id
	 */
	const toggleDetails = (id) => {
		if (expandedPanelDetails.includes(id)) {
			setExpandedPanelDetails(
				expandedPanelDetails.filter((panel) => panel !== id)
			);
			return;
		}

		setExpandedPanelDetails(expandedPanelDetails.concat(id));
	};

	async function handleDeletePanel(panel) {
		if (panel.toBeStored === true) {
			dispatch(removeNonStoredMeasure(panel.id));
			dispatch(removeFromEditableRecords(panel.id));
			dispatch(clearRecordChanges(panel.id));
			setFilteredPanelsList(
				filteredPanelsList.filter((p) => p.id !== panel.id)
			);
			return;
		}

		const res = await deleteRecord({ id: panel.id }).unwrap();
		// TODO: 🚨 Handle error
		if (res.success) {
			dispatch(removeNonStoredMeasure(panel.id));
			dispatch(removeFromEditableRecords(panel.id));
			dispatch(clearRecordChanges(panel.id));
			setPanelIds(panelIds.filter((id) => id !== panel.id));
			setFilteredPanelsList(
				filteredPanelsList.filter((p) => p.id !== panel.id)
			);
			refetch();
		}
	}

	function handleToggleVisibilityClick(panel) {
		const { id } = panel;
		if (!hidden.includes(id)) {
			dispatch(hideMeasure(id));
			dispatch(deselectMeasure(id));
			return;
		}
		dispatch(showMeasure(id));
		dispatch(selectMeasure(id));
	}

	const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = useState(false);
	const [qrcodeLinkData, setQRCodeLinkData] = useState(null);

	const onQRCodeClick = (panel) => {
		const coordinates = JSON.parse(panel.data)[0][0];
		const url = getMapyCzUrlFromKrovak(coordinates[0], coordinates[1], 18);
		setQRCodeLinkData(url);
		setIsQRCodeDialogOpen(true);
	};

	const onCopyClick = (panel) => {
		let coordinates;
		if (panel.toBeStored) {
			coordinates = panel.points;
		} else {
			coordinates = JSON.parse(panel.data)[0];
		}
		navigator.clipboard.writeText(
			getCoordinateString(getCenterPoint(flattenPoints(coordinates)), dimension)
		);
	};

	const handleEditPolygonClick = useCallback((panelId) => {
		dispatch(addToEditableRecords(panelId));
	}, []);

	const [updateRecord] = useUpdateRecordMutation();
	const [getRecord] = useLazyGetRecordQuery();

	const { changes } = useSelector((state) => state.records);

	const handleSaveChangesClick = useCallback(
		async (panel) => {
			const changesForRecord = changes[panel.id];

			if (changesForRecord) {
				const { points } = changesForRecord;

				const response = await getRecord({ id: panel.id }).unwrap();

				let area = null;
				if (response.data.type === RecordType.SOLAR_PANEL) {
					if (isValidPolygon(points)) {
						area = computeArea(points);
					} else {
						dispatch(
							addMessage({
								type: "error",
								message: "Zadané body nevytváří platný polygon",
							})
						);
						return;
					}
				}

				const recent =
					area != null
						? {
								...response.data,
								extra: { ...response.data.extra, size: area },
								data: points,
						  }
						: {
								...response.data,
								data: points,
						  };

				await updateRecord({
					id: panel.id,
					...recent,
				}).unwrap();

				dispatch(removeFromEditableRecords(panel.id));
				dispatch(clearRecordChanges(panel.id));
			}
		},
		[changes]
	);

	const hasChangesToSave = (panel) => {
		return panel.toBeStored === false || (changes[panel.id] ? true : false);
	};

	return {
		toggleDetails,
		handleToggleVisibilityClick,
		hasChangesToSave,
		handleSaveChangesClick,
		onCopyClick,
		onQRCodeClick,
		handleEditPolygonClick,
		handleDeletePanel,
		setIsQRCodeDialogOpen,
		filteredPanelsList,
		isLoading,
		isLoadingFaults,
		expandedPanelDetails,
		clientId,
		projectId,
		stateId,
		hidden,
		isQRCodeDialogOpen,
		qrcodeLinkData,
		canEdit,
	};
}
