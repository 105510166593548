import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	name: "",
};

export const technicianSlice = createSlice({
	name: "technician",
	initialState,
	reducers: {
		setTechnicianName: (state, action) => {
			state.name = action.payload;
		},
	},
});

export const { setTechnicianName } = technicianSlice.actions;

export default technicianSlice.reducer;
