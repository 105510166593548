import React from "react";

/**
 * @param {{children: React.ReactNode, className?: string}} props
 */
const InputLabel = ({ children, className }) => {
	return (
		<label
			className={`block text-left text-700 font-bold text-sm mb-2 text-primary ${
				className || ""
			}`}
			htmlFor="name"
		>
			{children}
		</label>
	);
};

export default InputLabel;
