import React from "react";

/**
 * @param {{
 * 	children: React.ReactNode,
 * 	onClick: () => void,
 * 	className?: string,
 * 	iconSrc?: string
 * }} props
 */
export const ButtonOutlined = ({
	children,
	onClick,
	className = "",
	iconSrc = false,
}) => {
	return (
		<button
			onClick={onClick}
			className={`${className} text-sm rounded-full border-2 bg-white min-w-fit flex justify-between items-center leading-tight max-w-[100px] transition-all max-h-10 font-bold px-3 hover:shadow-lg py-1 text-primaryDark`}
		>
			{children}
			{iconSrc && (
				<img
					className={"ml-1 pointer-events-none inline w-5 h-5"}
					src={iconSrc}
					alt=""
					width={12}
					height={12}
				/>
			)}
		</button>
	);
};
