import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DimensionType } from "../../api/dimensionType";
import { useGetRecordQuery } from "../../api/managementApi";
import { CalendarViewer } from "../../components/calendar/Calendar";
import { PanelFaultsTable } from "../../components/panels/PanelFaultsTable";

export const PanelOverview = () => {
	const { projectId, clientId } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const dimension = searchParams.get("dimension");
	const stateId = searchParams.get("stateId");
	const panelId = searchParams.get("panelId");

	const { data: panel, isLoading } = useGetRecordQuery(
		{
			id: panelId,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (!panel) {
		return <div>Panel not found</div>;
	}

	const solarPanelId = panel.data.extra.id;

	return (
		<div className="grid h-full grid-cols-[450px_minmax(430px,_1fr)] grid-rows-1">
			{/* Sidebar */}
			<section className="p-12 bg-white rounded-r-xl overflow-y-auto">
				{/* // TODO: 🚨 add to translations */}
				<h2 className="text-black font-bold text-xxxl">Název panelu</h2>

				<div className="mt-8">
					{/* // TODO: 🚨 add to translations */}
					<h3 className="text-black font-bold text-xxl">Podrobnosti</h3>

					<div className="mt-2">
						{/* // TODO: 🚨 add to translations */}
						<p className="text-primary font-bold text-xs">Typ panelu</p>
						<p className="text-zinc-500">
							{isLoading ? "Loading..." : panel.data.name}
						</p>
					</div>
					<div className="mt-2">
						{/* // TODO: 🚨 add to translations */}
						<p className="text-primary font-bold text-xs">Typ uchycení</p>
						<p className="text-zinc-500">
							{isLoading ? "Loading..." : panel.data.extra.anchorType || "N/A"}
						</p>
					</div>
					<div className="mt-2">
						{/* // TODO: 🚨 add to translations */}
						<p className="text-primary font-bold text-xs">Pozice PV modulu</p>
						<p className="text-zinc-500">
							{isLoading
								? "Loading..."
								: panel.data.extra.positionPvModule || "N/A"}
						</p>
					</div>
				</div>

				<div>
					<h2 className="mt-8 text-black font-bold text-xxl">
						{/* // TODO: 🚨 add to translations */}
						Historie panelu
					</h2>

					<CalendarViewer />
				</div>
			</section>

			{/* Main content */}
			<section className="py-12 px-12 bg-gray-100 overflow-y-auto">
				{/* HEADER */}
				<div className="flex justify-between relative">
					<button
						className="absolute -top-12 -right-14 m-4"
						onClick={() => {
							if (!dimension || !stateId) {
								return navigate(`/clients/${clientId}/projects/${projectId}`);
							}

							if (dimension === DimensionType.D3) {
								navigate(
									`/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
								);
							} else {
								navigate(
									`/clients/${clientId}/projects/${projectId}/map/${stateId}`
								);
							}
						}}
					>
						<img
							className={
								"pointer-events-none inline w-6 h-6 mr-2 rounded-full shadow-md"
							}
							src="/images/close.svg"
							alt=""
							width={12}
							height={12}
						/>
					</button>
					<h2 className="ml-4 text-black font-bold text-xxxl">
						{/* // TODO: 🚨 add to translations */}
						Poruchy panelu
					</h2>

					<button
						className="py-2 px-4 text-sm rounded-full text-white bg-primary  min-w-fit flex justify-between items-center leading-tight max-w-[100px] transition-all max-h-10 font-bold hover:shadow-lg"
						onClick={() => {
							navigate(
								`/clients/${clientId}/projects/${projectId}/panels/new-fault?panelId=${panelId}&solarPanelId=${solarPanelId}&dimension=${dimension}&stateId=${stateId}`
							);
						}}
					>
						<img
							className={"pointer-events-none inline w-3 h-3 mr-2"}
							src="/images/viewer_measurecontrol_open.svg"
							alt=""
							width={12}
							height={12}
						/>
						{/* // TODO: 🚨 add to translations */}
						Nová porucha
					</button>
				</div>

				{/* FAULTS */}
				<div className="mt-8">
					{/* // TODO: 🚨 add to translations */}
					<h3 className="ml-4 text-black font-bold text-xxl">Aktivní</h3>

					<div className="mt-2 ml-4 flex gap-2 flex-wrap">
						{panel.data.extra.faults
							.filter((fault) => fault.status !== "SOLVED")
							.map((fault) => (
								<div
									key={fault.id}
									className={`${
										fault.status === "ACTIVE"
											? "text-red-700 border-red-700"
											: "text-violet-600 border-violet-600"
									} py-2 px-8 font-bold text-sm rounded-full bg-transparent border-2 min-w-fit flex justify-between items-center leading-tight max-w-[100px] transition-all max-h-10 hover:shadow-lg`}
								>
									{fault.name}
								</div>
							))}
					</div>
				</div>

				{/* HISTORY */}
				<section className="mt-6">
					{/* // TODO: 🚨 add to translations */}
					<h3 className="ml-4 text-black font-bold text-xxl">Historie</h3>

					<PanelFaultsTable
						faultsList={panel.data.extra.faults.map((f) => ({
							...f,
							createdAt: new Date(f.createdAt).toLocaleDateString(),
						}))}
					/>
				</section>
			</section>
		</div>
	);
};
