import { useDispatch, useSelector } from "react-redux";
import { setPanelSearchText } from "../redux/panel/panelSlice";

const useSearchPanels = () => {
	const dispatch = useDispatch();

	const panelsSearchText = useSelector((state) => state.panel.searchText);

	const setPanelsSearchText = (text) => {
		dispatch(setPanelSearchText(text));
	};

	return { panelsSearchText, setPanelsSearchText };
};

export default useSearchPanels;
