import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_URL } from "../config";
import { getToken } from "../providers/authProvider";

export const technicianLogApi = createApi({
	reducerPath: "technicianLogApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API_URL,
		mode: "cors",
		prepareHeaders: (headers) => {
			const token = getToken();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getTechnicianLogDetails: builder.query({
			query: ({ technicianLogId }) => ({
				url: `technician-log/${technicianLogId}`,
				method: "GET",

				responseHandler: (res) => {
					return res.json().then((data) => {
						return data.data;
					});
				},
			}),
			providesTags: ["TechnicianLogs"],
		}),
		createTechnicianLog: builder.mutation({
			query: ({ faultId, technicianName, action, note, date }) => ({
				url: "technician-log",
				method: "POST",
				body: {
					faultId,
					technicianName,
					action,
					note,
					date,
				},
			}),
			invalidatesTags: ["TechnicianLogs", "Faults"],
		}),
		patchTechnicianLog: builder.mutation({
			query: ({ technicianLogId, action }) => ({
				url: `technician-log/${technicianLogId}`,
				method: "PATCH",
				body: {
					action,
				},
			}),
			invalidatesTags: ["TechnicianLogs", "Faults"],
		}),
	}),
});

export const {
	useGetTechnicianLogDetailsQuery,
	useCreateTechnicianLogMutation,
	usePatchTechnicianLogMutation,
} = technicianLogApi;
