import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";
import { getToken } from "../providers/authProvider";
import { getMinMaxCoordinates } from "../utils/geo";

export const XYZPointsOmitZ = (points) => {
	return points.map((p) => [p.position.x, p.position.y]);
};

//[[x,y,z]...] => [[x,y]...]
export const pointsArrayOmitZ = (points) => {
	// array of pointsm, i want not include z
	return points.map((p) => [p[0], p[1]]);
};

export const convertVolumeResult = (ccResult) => {
	const volumeString = ccResult?.result?.Volume;

	if (!volumeString) throw new Error("Volume not found in result");

	const number = parseFloat(removeUnwantedCharsFromStringNumber(volumeString));
	const formattedNumber = number.toLocaleString("cs-CZ");

	return {
		volume: formattedNumber + " m3",
	};
};

const removeUnwantedCharsFromStringNumber = (numberString) => {
	const res = numberString
		.replaceAll(",", "")
		.replaceAll("m", "")
		.replaceAll("(", "")
		.replaceAll(")", "");

	return res;
};

export const convertCompareResult = ({
	ccResult,
	inputLas: { inputFileId, inputLasInfo },
	compareLas: { compareFileId, compareLasInfo },
}) => {
	const addedVolume = ccResult?.result[inputFileId]?.volume;
	const removedVolume = ccResult?.result[compareFileId]?.volume;
	if (typeof addedVolume !== "number" || typeof removedVolume !== "number") {
		throw new Error("Volume not found in result");
	}
	const volumeDiff = addedVolume - removedVolume;

	const volumeFloat = parseFloat(volumeDiff).toFixed(2);
	const addedVolumeFloat = parseFloat(addedVolume).toFixed(2);
	const removedVolumeFloat = parseFloat(removedVolume).toFixed(2);

	return {
		result: volumeFloat,
		positiveChange: addedVolumeFloat,
		negativeChange: removedVolumeFloat,
		// grid step should be the same for both inputFileId and compareFileId, so either one is fine
		gridStep: ccResult?.result[inputFileId].step,
		lasInfo: {
			input: inputLasInfo?.data,
			compare: compareLasInfo?.data,
		},
	};
};

export const computeApi = createApi({
	reducerPath: "computeApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}`,
		prepareHeaders: (headers) => {
			const token = getToken();
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		convertPotree: builder.query({
			query: (fileId) => ({
				url: "calc/create-potree-folder",
				method: "POST",
				body: {
					inputFile: fileId,
				},
			}),
		}),
		convertTiff: builder.query({
			query: (fileId) => ({
				url: "calc/make-cog-from-tif",
				method: "POST",
				body: {
					inputFile: fileId,
				},
			}),
		}),
		getPotreeFolderContent: builder.query({
			query: ({ calculationId, fileName }) => ({
				url: `calc/potree-folder-content/${calculationId}/${fileName}`,
				method: "GET",
			}),
		}),
		computeVolume: builder.query({
			query: ({ file, polygonPoints }) => ({
				url: "cc/cloudcompare-compute",
				method: "POST",
				body: {
					inputFile: file,
					polygonPoints: polygonPoints, // [[x,y]...]
				},
			}),
		}),
		compareVolume: builder.query({
			query: ({ inputFile, compareFile, polygonPoints }) => ({
				url: `calc/cloudcompare-compare`,
				method: "POST",
				body: {
					inputFile: inputFile,
					compareFile: compareFile,
					polygonPoints: polygonPoints, // [[x,y]...]
				},
			}),
		}),
		getLasInfo: builder.query({
			query: (fileId) => ({
				url: `calc/las-info`,
				method: "POST",
				body: {
					inputFile: fileId,
				},
			}),
		}),
		getLasExtremePoints: builder.query({
			query: (
				{ fileId, polygonPoints, measureDbId } /*to match in reducer */
			) => ({
				url: `calc/las-extremes`,
				method: "POST",
				body: {
					inputFile: fileId,
					polygonPoints: [...polygonPoints, polygonPoints[0]], // [[x,y]...]
				},
				responseHandler: (res) => {
					return res.json().then((data) => {
						return {
							minZ: getMinMaxCoordinates(data.data.min),
							maxZ: getMinMaxCoordinates(data.data.max),
							measureDbId,
						};
					});
				},
			}),
		}),
		// createRecordPdf: builder.query({
		//   query: ({ html, attachments }) => ({
		//     url: `calc/pdf-render`,
		//     method: "POST",
		//     body: {
		//       html,
		//       attachments,
		//     },
		//   }),
		//   responseHandler: (res) => {
		//     console.log("responseHandler", res.text)
		//     return res.text();
		//   },
		// }),
	}),
});

export function getPotreeFileUrl(calculationId, fileName) {
	return `${API_URL}/calc/potree-folder-content/${calculationId}/${fileName}`;
}

export const {
	useLazyComputeVolumeQuery,
	useLazyCompareVolumeQuery,
	useLazyConvertPotreeQuery,
	useLazyConvertTiffQuery,
	useLazyGetLasInfoQuery,
	useLazyGetLasExtremePointsQuery,
	useLazyGetPotreeFolderContentQuery,
	// useLazyCreateRecordPdfQuery,
} = computeApi;
