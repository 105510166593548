import React from "react";
import { useSelector } from "react-redux";
import { DEMO_CLIENT_ID } from "../config";
import { AuthContext } from "../providers/authProvider";

const useAuth = () => {
  const {
    user,
    login,
    logout,
    token,
    error,
    isLoading,
    isLoginLoading,
    linkToken,
    setLinkToken,
    linkAccessData,
    linkTokenLevel,
  } = React.useContext(AuthContext);

  const isDemo = useSelector(state => state.auth?.me?.clientId === DEMO_CLIENT_ID); //linkToken && linkToken === DEMO_TOKEN && token == null;
  const isAdmin = useSelector((state) => state.auth?.me?.isAdmin);
  const isTechnician = useSelector((state) => state.auth?.me?.isTechnician);

  return {
    user,
    login,
    logout,
    token,
    error,
    isLoading,
    isLoginLoading,
    linkToken,
    setLinkToken,
    linkAccessData,
    linkTokenLevel,
    isAdmin,
    isTechnician,
    isDemo,
  };
};

export default useAuth;
