import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { namespaces } from "../../consts/i18n";
import Logo from "../Logo";
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components

export const ComparisonToPrint = React.forwardRef((props, ref) => {
	const { data, states } = props;

	const { t } = useTranslation(namespaces.viewer);

	const { client, project } = useSelector((state) => state.info);

	const stateNameDates =
		states?.length > 1 &&
		states.map((state) => ({
			name: state.name,
			date: new Date(state.createdAt).toLocaleDateString(),
		}));

	return (
		<div
			ref={ref}
			className="m-[65px] flex flex-col text-[#1E2D2F]"
			style={{ zoom: 1.25 }}
		>
			<Logo className="absolute right-[70px] top-[43px] w-[42px]"></Logo>
			<div>
				<h1 className="text-center text-[24px] text-700 font-bold leading-[29px]">
					{t("printReport.title")}
				</h1>
				<h2 className="text-center text-[12px] text-400"></h2>
			</div>

			<div className="flex gap-[10px] flex-col mt-[18px]">
				<div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
					<div className="text-[11px] leading-[13px]">
						{t("printReport.client")}
					</div>
					<div className="text-700  font-bold text-[11px] leading-[13px]">
						{client?.name}
					</div>
				</div>

				<div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
					<div className="text-[11px] leading-[13px]">
						{t("printReport.project")}
					</div>
					<div className="text-[11px] leading-[13px] text-700  font-bold">
						{project?.name}
					</div>
				</div>

				<div className="w-full rounded-lg bg-[#F6F6F6] py-[9px] px-[22px]">
					<div className="flex justify-between items-center">
						<div className="text-[11px] leading-[13px] text-700  font-bold">
							{t("printReport.state0")}
						</div>
						<div className="text-[11px] leading-[13px] text-700  font-bold">
							{stateNameDates[0]?.name}
						</div>
					</div>
					<div className="flex justify-between items-center">
						<div className="text-[11px] leading-[13px]">
							{stateNameDates[0]?.date &&
								t("printReport.date") + ": " + stateNameDates[0]?.date}
						</div>
						<div className="text-[11px] leading-[13px]">
							{data.lasInfo?.input?.PointCloud &&
								t("printReport.points") +
									": " +
									data.lasInfo?.input?.PointCloud}
						</div>
					</div>
				</div>

				<div className="w-full rounded-lg bg-[#F6F6F6] py-[9px] px-[22px]">
					<div className="flex justify-between items-center">
						<div className="text-[11px] leading-[13px] text-700  font-bold">
							{t("printReport.state1")}
						</div>
						<div className="text-[11px] leading-[13px] text-700  font-bold">
							{stateNameDates[1]?.name}
						</div>
					</div>
					<div className="flex justify-between items-center">
						<div className="text-[11px] leading-[13px]">
							{stateNameDates[1]?.date &&
								t("printReport.date") + ": " + stateNameDates[1]?.date}
						</div>
						<div className="text-[11px] leading-[13px]">
							{data.lasInfo?.compare?.PointCloud &&
								t("printReport.points") +
									": " +
									data.lasInfo?.compare?.PointCloud}
						</div>
					</div>
				</div>
				<div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
					<div className="text-[11px] leading-[13px]">
						{t("printReport.gridStep")}
					</div>
					<div className="text-[11px] leading-[13px]  text-700  font-bold">
						{data.gridStep} m
					</div>
				</div>
			</div>

			<div className="flex items-center justify-center">
				<img
					src={data.image}
					alt="comparison"
					className="h-[218px] my-[40px]"
				/>
			</div>

			<div className="flex gap-[10px] flex-col">
				<div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
					<div className="text-[11px] leading-[13px]">
						{t("printReport.positiveChange")}
					</div>
					<div className="text-[11px] leading-[13px]  text-700  font-bold">
						{data.changePositive} m³
					</div>
				</div>

				<div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px]">
					<div className="text-[11px] leading-[13px]">
						{t("printReport.negativeChange")}
					</div>
					<div className="text-[11px] leading-[13px] text-700  font-bold">
						{data.changeNegative} m³
					</div>
				</div>
			</div>

			<div className="w-full flex justify-between items-center rounded-lg bg-[#F6F6F6] h-[30px] px-[22px] mt-[37px]">
				<div className="text-[11px] leading-[13px] text-700  font-bold">
					{t("printReport.result")}
				</div>
				<div className="text-[11px] leading-[13px] text-700  font-bold">
					{data.result} m³
				</div>
			</div>

			<div className="text-[8px] mt-[20px]">
				<p>{t("printReport.footer")}</p>

				<p>
					{t("printReport.today")} {new Date().toLocaleDateString()}
				</p>
			</div>

			{/* 
      
            <h1 className="text-xxl  bg-primary text-white text-bold px-5 p-2">
              {client?.name} - {project?.name}
            </h1>
            <h2 className="text-lg bg-primaryLight text-primary p-5">
              {t("printableComparison.title")} : {states[0]?.name} vs{" "}
              {states[1]?.name}
            </h2>
      <div className="flex items-start justify-center">
      <div className="w-[300px] flex flex-col hover:shadow-lg bg-white rounded-lg px-[20px] py-[12px] text-700 text-sm font-bold gap-[6px]">
      <div className="flex justify-between items-center">
      <span> {t("printableComparison.original")}</span>
      <span>
      {data.original} m<sup>3</sup>
      </span>
      </div>
      
      <div className="flex justify-between items-center">
            <span className="self-start">
              {t("printableComparison.change")}
            </span>
            <div>
              <div>
                {data.changePositive} m<sup>3</sup>
              </div>
              <div>
                {data.changeNegative} m<sup>3</sup>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center border-t border-gb1 pt-[2px]">
            <span> {t("printableComparison.result")}</span>
            <span>
              {data.result} m<sup>3</sup>
            </span>
          </div>
        </div>
      </div> */}
		</div>
	);
});
