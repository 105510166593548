import { createSlice } from "@reduxjs/toolkit";

export const DisplayType = /** @type {const} */ ({
	MEASURES: "MEASURES",
	RECORDS: "RECORDS",
	SOLAR_PANELS: "SOLAR_PANELS",
});

export const initialState = {
	activeDisplayMode: DisplayType.MEASURES,
};

export const viewerSlice = createSlice({
	name: "viewer",
	initialState,
	reducers: {
		setDisplayMode: (state, action) => {
			state.activeDisplayMode = action.payload;
		},
	},
});

export const { setDisplayMode } = viewerSlice.actions;

export default viewerSlice.reducer;
