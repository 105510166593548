import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DimensionType } from "../../../api/dimensionType";
import { namespaces } from "../../../consts/i18n";
import useAuth from "../../../hooks/useAuth";
import useViewerDisplayMode from "../../../hooks/useViewerDisplayMode";
import { clearMeasures } from "../../../redux/measures2/measures2Reducer";
import { DisplayType } from "../../../redux/viewer/viewerReducer";
import TabItem from "../TabItem";

/**
 * @param {Record<string, any>} state.info
 */
const isRecordsEnabledForProject = (project) => {
	const enabled = project?.allowedRecordTypes?.length > 0;
	return !!enabled;
};

/**
 * @param {Record<string, any>} state.info
 */
const isPanelsEnabledForProject = (project) => {
	const enabled = project?.allowedRecordTypes?.includes("SOLAR_PANEL");
	return !!enabled;
};

/**
 * Renders the tabs so the user can select which display mode to use
 * @param {{dimensionType: DimensionType}} props
 */
function DisplayTypeTabs({ dimensionType }) {
	const dispatch = useDispatch();
	const { isTechnician } = useAuth();
	const { project } = useSelector((state) => state.info);
	const [displayMode, setDisplayMode] = useViewerDisplayMode();

	const { t } = useTranslation(namespaces.viewer);

	return (
		<div className="flex flex-row items-center pt-[20px] gap-[21px] border-b-[2px] border-b-[#FBFBFB] pl-[21px] bg-[#EFEFEF]">
			{!isTechnician && (
				<TabItem
					label={t("sidebar.tabs.measurements")}
					active={displayMode === DisplayType.MEASURES}
					onClick={() => {
						// TODO: best to trigger the modal to confirm action
						// as this action is destructive
						dispatch(clearMeasures());
						setDisplayMode(DisplayType.MEASURES);
					}}
				/>
			)}
			{!isTechnician &&
				dimensionType === DimensionType.D2 &&
				isRecordsEnabledForProject(project) && (
					<TabItem
						label={t("sidebar.tabs.records")}
						active={displayMode === DisplayType.RECORDS}
						onClick={() => {
							// TODO: best to trigger the modal to confirm action
							// as this action is destructive
							dispatch(clearMeasures());
							setDisplayMode(DisplayType.RECORDS);
						}}
					/>
				)}
			{isPanelsEnabledForProject(project) && (
				<TabItem
					label={t("sidebar.tabs.panels")}
					active={displayMode === DisplayType.SOLAR_PANELS}
					onClick={() => {
						// TODO: best to trigger the modal to confirm action
						// as this action is destructive
						dispatch(clearMeasures());
						setDisplayMode(DisplayType.SOLAR_PANELS);
					}}
				/>
			)}
		</div>
	);
}

export default DisplayTypeTabs;
