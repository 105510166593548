import React, { useCallback, useEffect, useRef } from "react";
import DialogWrapper from "./DialogWrapper";
import { namespaces } from "../../consts/i18n";
import { useTranslation } from "react-i18next";
import DialogTitle from "./DialogTitle";
import TextButton from "../buttons/TextButton";
import CloseButton from "../buttons/CloseButton";
import FVEForm from "../forms/FVEForm";
import GraveYardForm from "../forms/GraveYardForm";
import ParksAndGardensForm from "../forms/ParksAndGardensForm";
import FacilityForm from "../forms/FacilityForm";
import GeneralForm from "../forms/GeneralForm";
import TextInput from "../inputs/TextInput";
import CheckboxInput from "../inputs/CheckboxInput";
import SelectBoxInput from "../inputs/SelectboxInput";
import { RecordType, filterRecordTypesForToolMode } from "../../api/consts";
import { useSelector, useDispatch } from "react-redux";
import {
  useCreateRecordMutation,
  useCreateMeasureMutation,
  useDeleteRecordMutation,
} from "../../api/managementApi";
import {
  setMeasureDbId,
  markMeasureToBeStored,
  removeMeasure,
} from "../../redux/measures/measuresReducer";
import LoadingBar from "../LoadingBar";
import useUploadRecordFiles from "../../hooks/useUploadRecordFiles";
import { removeNonStoredRecord } from "../../redux/records/recordsReducer";
import { removeNonStoredMeasure } from "../../redux/measures2/measures2Reducer";
import TogglesSelect from "../inputs/TogglesSelect";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import useAuthInfo from "../../hooks/useAuthInfo";

const CreateRecordDialog = ({ isOpen, onClose, initialData }) => {
  const { project } = useSelector((state) => state.info);
  const { mode } = useSelector((state) => state.measures);
  const { t } = useTranslation(namespaces.viewer);
  const dispatch = useDispatch();

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();
  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

  const [recordTypeOptions, setRecordTypeOptions] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [checkRecordVisible, setCheckRecordVisible] = React.useState(false);
  const [data, setData] = React.useState({
    ...initialData,
  });

  const [createRecord, { isLoading, isSuccess, isError, error }] =
    useCreateRecordMutation();

  const [createMeasure, { isLoading: isMeasureLoading }] =
    useCreateMeasureMutation();

  const [deleteRecord, { isLoading: isDeleteLoading }] =
    useDeleteRecordMutation();

  const [issues, setIssues] = React.useState(null);

  const {
    uploadFiles,
    isUploading,
    uploadProgress,
    uploadError,
    uploadAbortController,
    clearUploadError,
  } = useUploadRecordFiles();

  const handleCancel = useCallback(() => {
    // cancel upload if in progress
    if (isUploading) {
      uploadAbortController.current.abort();
    }

    onClose();
  }, [onClose, isUploading, uploadAbortController]);

  const handleSubmit = useCallback(async () => {
    const { measure, record } = initialData;
    // console.log(data);
    // console.log(formData);
    // console.log(measure);
    // omit nore from formData
    const { note, ...rest } = formData;
    let uploadFailed = false;
    if (data.isRecord) {
      let response;
      try {
        response = await createRecord({
          projectId: project.id,
          name: data.name,
          type: data.recordType,
          note: note,
          data: measure.points,
          extra: rest,
          showValues: data.recordType === RecordType.AREA //data.isMeasure,
        }).unwrap();
      } catch (err) {
        // console.log(err);

        if (err.data?.issues) {
          setIssues(err.data.issues);
          return;
        }

        throw err;
      }

      const newRecord = response.data;

      if (newRecord && formData.files && formData.files.length > 0) {
        const res = await uploadFiles(newRecord.id, formData.files);
        if (!res) {
          uploadFailed = true;
        }
      }

      if (uploadFailed) {
        // remove record
        await deleteRecord(newRecord.id).unwrap();
        return;
      }
    }

    if (data.isMeasure) {
      try {
        const res = await createMeasure({
          projectId: project.id,
          dimension: mode,
          name: data.name,
          type: measure.type,
          data: measure.points,
        }).unwrap();
        // console.log("res", res);
      } catch (err) {
        // console.log(err);
        if (err.data?.issues) {
          setIssues(err.data.issues);
          return;
        }

        throw err;
      }

      if (initialData.isFromRecord) {
        // need to add new measure
        dispatch(removeNonStoredRecord(record.id));
      } else {
        dispatch(removeNonStoredMeasure(measure.id));
        // dispatch(setMeasureDbId({ id: measure.id, dbId: res.data.id }));
        // dispatch(markMeasureToBeStored({ id: measure.id, toBeStored: true }));
      }
    } else {
      if (record) {
        dispatch(removeNonStoredRecord(record.id));
      } else {
        dispatch(removeNonStoredMeasure(measure.id));
      }
    }

    onClose();
  }, [
    data,
    formData,
    project,
    createRecord,
    initialData,
    createMeasure,
    dispatch,
    mode,
  ]);

  useEffect(() => {
    if (!isOpen) return;
    if (!project) return;

    const { measure } = initialData;

    const filteredTypes = filterRecordTypesForToolMode(
      // filter record types based on tool mode
      project.allowedRecordTypes,
      measure.type
    );
    const recordType = filteredTypes[0];

    setRecordTypeOptions(
      project.allowedRecordTypes.map((recordType) => ({
        value: recordType,
        label: t(`form.${recordType}`),
        disabled: !filteredTypes.includes(recordType),
      }))
    );

    if (filteredTypes.length === 0) {
      // if no record types are allowed, create measure
      setCheckRecordVisible(false);
      setData({
        name: measure.name,
        recordType: null,
        isRecord: false,
        isMeasure: true,
      });
    } else {
      // if record types are allowed, create record or measure
      setCheckRecordVisible(true);
      setData({
        name: measure.name,
        recordType,
        isRecord: initialData.isFromRecord,
        isMeasure: !initialData.isFromRecord,
      });
      setFormData({
        points: measure.points,
      });
    }

    setIssues(null);
    clearUploadError();
  }, [isOpen, project, initialData, clearUploadError]);

  const handleChangeName = (value) => {
    setData({ ...data, name: value });
  };



  const canSave = data.isRecord || data.isMeasure;

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      <div
        className="relative bg-white rounded-xl w-[671px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel} />
        <div className="flex flex-col gap-[30px] items-center px-[20px]">
          <DialogTitle title={t("createRecordDialog.title")}></DialogTitle>

          <div className="flex flex-row justify-between gap-[40px]">
            <div className="flex flex-row justify-between items-end w-[313px]">
              <TextInput
                inputClassName="h-[38px] w-[313px]"
                label={t("createRecordDialog.name")}
                value={data.name}
                onChange={handleChangeName}
                extraError={getErrorForPath(issues, ["name"])}
              ></TextInput>
            </div>

            {checkRecordVisible && (
              <div className="flex flex-col gap-[24px] w-[176px]">
                <CheckboxInput
                  label={t("createRecordDialog.isRecord")}
                  checked={data.isRecord}
                  disabled={canEdit === false}
                  onChange={(checked) =>
                    setData({ ...data, isRecord: checked })
                  }
                ></CheckboxInput>

                <CheckboxInput
                  label={t("createRecordDialog.isMeasure")}
                  checked={data.isMeasure}
                  disabled={canEdit === false}
                  onChange={(checked) =>
                    setData({ ...data, isMeasure: checked })
                  }
                ></CheckboxInput>
              </div>
            )}
          </div>

          {data?.isRecord && (
            <div
              className="flex flex-col gap-[24px]
            overflow-y-auto overflow-x-hidden max-h-[500px]
            bg-bg1 rounded-[30px] py-[30px] w-[524px] px-[40px]
          "
            >
              {/* <SelectBoxInput
                label={t("form.recordType")}
                options={recordTypeOptions}
                selectedOption={data.recordType}
                onChange={({ value }) => {
                  setData({ ...data, recordType: value });
                }}
              ></SelectBoxInput> */}

              <TogglesSelect
                label={t("form.recordType")}
                options={recordTypeOptions}
                selectedOption={data.recordType}
                onChange={({ value }) => {
                  setData({ ...data, recordType: value });
                  setFormData((prev) => ({
                    points: prev.points,
                    isRecord: prev.isRecord,
                    isMeasure: prev.isMeasure,
                  }));
                  console.log("record type changed: " + value );
                }}
              ></TogglesSelect>

              {data.recordType === RecordType.FAULT && (
                <FVEForm
                  data={formData}
                  onChange={setFormData}
                  fillDefault={true}
                  issues={issues}
                />
              )}

              {data.recordType === RecordType.CEMETERY && (
                <GraveYardForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}

              {data.recordType === RecordType.PARK && (
                <ParksAndGardensForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}

              {data.recordType === RecordType.AREA && (
                <FacilityForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}

              {data.recordType === RecordType.GENERIC && (
                <GeneralForm
                  data={formData}
                  onChange={setFormData}
                  issues={issues}
                />
              )}
            </div>
          )}

          <div className="px-[80px] w-full">
            {isUploading && (
              <LoadingBar progress={uploadProgress * 100}></LoadingBar>
            )}
          </div>

          {uploadError && (
            <div className="text-red-500 text-center text-xs">
              {uploadError}
            </div>
          )}

          <div className="flex flex-row justify-between gap-[64px] mt-[20px]">
            <TextButton
              className={"h-[56px]"}
              secondary={true}
              label={t("createRecordDialog.cancel")}
              onClick={handleCancel}
            />
            <TextButton
              disabled={!canSave}
              isLoading={isLoading}
              className={"h-[56px]"}
              label={t("createRecordDialog.create")}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </DialogWrapper>
  ) : null;
};

export default CreateRecordDialog;
