import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import InputLabel from "./InputLabel";

const TextInput = ({
	label,
	defaultValue,
	onChange,
	onValidityChange,
	placeholder,
	lightTheme,
	inputClassName,
	labelClassName,
	extraError,
	autoComplete,
	...otherProps
}) => {
	const [value, setValue] = React.useState(defaultValue || "");
	const [inputError, setInputError] = React.useState(false);

	const { t } = useTranslation(namespaces.common);

	React.useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleChange = (e) => {
		setValue(e.target.value);
		onChange(e.target.value);

		if (e.target.validity.patternMismatch) {
			setInputError(true);
		} else {
			setInputError(false);
		}
	};

	React.useEffect(() => {
		if (onValidityChange) {
			onValidityChange(!inputError);
		}
	}, [inputError]);

	const theme = lightTheme ? "bg-white" : "bg-bg1";

	return (
		<div>
			<InputLabel className={labelClassName}>{label}</InputLabel>
			<input
				onChange={handleChange}
				className={`rounded-lg min-w-full px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline ${theme} ${
					extraError ? "bg-red-100 " : ""
				} ${inputClassName || ""} ${
					otherProps?.pattern ? "invalid:bg-red-100" : ""
				}`}
				id="name"
				type="text"
				placeholder={placeholder || ""}
				value={value}
				autoComplete={autoComplete || "off"}
				{...otherProps}
			/>
			{inputError && (
				<p className="text-red-500 text-xs">{t("dialogs.inputFormatError")}</p>
			)}
			{extraError && (
				<p className="text-red-500 text-xs">
					{t(`dialogs.serverErrors.${extraError?.message}`)}
				</p>
			)}
		</div>
	);
};

export const TextInputSmall = (props) => {
	return (
		<TextInput
			{...props}
			inputClassName="h-[36px] text-[12px] px-[10px] py-[5px] font-medium rounded-[14px]"
		/>
	);
};

export const TextInputPrint = (props) => {
	return (
		<TextInput
			{...props}
			inputClassName={`h-[36px] text-[12px] px-[10px] py-[5px] font-medium rounded-[14px]`}
		></TextInput>
	);
};

export default TextInput;
