import { createSlice } from "@reduxjs/toolkit";
import { computeApi } from "../../api/computeApi";

export const initialState = {
	nonStored: [],
	hidden: [],
	selected: [],
	editable: [],
	minMax: {}, // map with key as fileId + polygon points and value as min max data
	search: {
		text: "",
	},
	filter: {
		types: [],
	},
};

export const measures2Slice = createSlice({
	name: "measures2",
	initialState,
	reducers: {
		addNonStoredMeasure: (state, action) => {
			if (action.payload.isSolarPanel) {
				state.nonStored.push({ ...action.payload, toBeStored: true });
				state.editable.push(action.payload.id);
				return;
			}
			state.nonStored.push({ ...action.payload, toBeStored: false });

			// automatically add to editable
			state.editable.push(action.payload.id);
		},
		removeNonStoredMeasure: (state, action) => {
			state.nonStored = state.nonStored.filter((r) => r.id !== action.payload);

			// automatically remove from editable
			state.editable = state.editable.filter((id) => id !== action.payload);
		},
		updateNonStoredMeasure: (state, action) => {
			state.nonStored = state.nonStored.map((r) => {
				if (r.id === action.payload.id) {
					return { ...r, ...action.payload };
				} else {
					return r;
				}
			});
		},
		showMeasure: (state, action) => {
			state.hidden = state.hidden.filter((id) => id !== action.payload);
		},
		hideMeasure: (state, action) => {
			state.hidden.push(action.payload);
		},
		selectMeasure: (state, action) => {
			state.selected.push(action.payload);
		},
		deselectMeasure: (state, action) => {
			state.selected = state.selected.filter((id) => id !== action.payload);
		},
		addToEditableMeasures: (state, action) => {
			if (state.editable.includes(action.payload)) return;
			state.editable.push(action.payload);
		},
		removeFromEditableMeasures: (state, action) => {
			state.editable = state.editable.filter((id) => id !== action.payload);
		},
		clearMeasures: (state, action) => {
			state.nonStored = [];
			state.hidden = [];
			state.selected = [];
			state.editable = [];
			// state.minMax = {}; -> this is not cleared because it is used for caching
		},
		setMeasureSearchText: (state, action) => {
			state.search.text = action.payload;
		},
		setMeasureFilterTypes: (state, action) => {
			state.filter.types = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder.addMatcher(
			computeApi.endpoints.getLasExtremePoints.matchFulfilled,
			(state, action) => {
				const payload = action.payload;
				const fileId = action.meta.arg.originalArgs.fileId;
				const polygonPoints = action.meta.arg.originalArgs.polygonPoints;

				// polygon points will be key of map
				const key = getKeyForMinMax(fileId, polygonPoints);
				if (state.minMax[key]) {
					return;
				}

				state.minMax[key] = {
					min: payload.minZ,
					max: payload.maxZ,
				};
			}
		);
	},
});

export const getKeyForMinMax = (fileId, polygonPoints) => {
	return fileId + "_" + polygonPoints.map((p) => p.join(",")).join(",");
};

export const {
	addNonStoredMeasure,
	removeNonStoredMeasure,
	updateNonStoredMeasure,
	showMeasure,
	hideMeasure,
	selectMeasure,
	deselectMeasure,
	addToEditableMeasures,
	removeFromEditableMeasures,
	clearMeasures,
	setMeasureSearchText,
	setMeasureFilterTypes,
} = measures2Slice.actions;

export default measures2Slice.reducer;
