import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	useLazyGetMeasuresQuery,
	useLazyListPanelsQuery,
	useLazySearchMeasuresQuery,
} from "../api/managementApi";
import { toolModeType } from "../redux/pointCloudViewer/pointCloudViewerReducer";
import useAuth from "./useAuth";
import useViewerDisplayMode from "./useViewerDisplayMode";

const filterMeasures = (measures, filteredTypes) => {
	return measures.filter((measure) => {
		if (filteredTypes.length === 0) return true;

		return filteredTypes.every((filter) => {
			switch (filter) {
				case "saved":
					return measure.toBeStored;
				case "distance":
					return measure.type === toolModeType.DISTANCE;
				case "area":
					return measure.type === toolModeType.AREA;
				case "volume":
					return measure.type === toolModeType.VOLUME;
				default:
					return false;
			}
		});
	});
};

const useGetMeasures = ({ projectId, dimension }) => {
	const { isTechnician } = useAuth();

	const nonStoredMeasures = useSelector((state) => state.measures2.nonStored);
	const searchText = useSelector((state) => state.measures2.search.text);
	const filterTypes = useSelector((state) => state.measures2.filter.types);

	const [getMeasures, { data: serverMeasures }] = useLazyGetMeasuresQuery();
	const [getSolarPanels, { data: solarPanels }] = useLazyListPanelsQuery();

	const [searchMeasures, { data: searchedMeasures }] =
		useLazySearchMeasuresQuery();

	const [allMeasures, setAllMeasures] = useState([]);

	const [displayMode] = useViewerDisplayMode();

	useEffect(() => {
		if (!projectId || !dimension) return;
		// technician is unauthorized for the calls below
		// which triggers an error modal
		if (typeof isTechnician === "undefined") return;

		if (isTechnician || displayMode === "SOLAR_PANELS") {
			getSolarPanels({
				projectId,
			});
			return;
		}

		if (searchText && searchText.length > 0) {
			searchMeasures({
				projectId,
				dimension,
				searchString: searchText,
			});
		} else {
			getMeasures({
				projectId,
				dimension,
			});
		}
	}, [searchText, projectId, dimension, isTechnician, displayMode]);

	useEffect(() => {
		if (!serverMeasures) return;

		if (displayMode === "SOLAR_PANELS") {
			setAllMeasures(solarPanels);
			return;
		}

		const allMeasures = [
			...serverMeasures.filter((r) =>
				nonStoredMeasures.find((nr) => nr.id === r.id) ? false : true
			),
			...nonStoredMeasures.filter((m) => !m.isSolarPanel),
		];

		setAllMeasures(filterMeasures(allMeasures, filterTypes));
	}, [
		serverMeasures,
		nonStoredMeasures,
		filterTypes,
		displayMode,
		projectId,
		solarPanels,
	]);

	// console.log("serverMeasures", serverMeasures);
	return {
		measures: (searchText ? searchedMeasures : allMeasures) || [],
	};
};

export default useGetMeasures;
