import { useSelector } from "react-redux";
import { DEMO_CLIENT_ID } from "../config";

const useAuthInfo = () => {
  const isAdmin = useSelector((state) => state.auth?.me?.isAdmin);
  const isTechnician = useSelector((state) => state.auth?.me?.isTechnician);
  const isVisitor = useSelector((state) => state.auth?.me?.isVisitor);
  const isDemo = useSelector(state => state.auth?.me?.clientId === DEMO_CLIENT_ID);

  const isStateLinkLevel = useSelector(
    (state) => state.auth?.me?.stateId != null
  );

  const isProjectLinkLevel = useSelector(
    (state) => state.auth?.me?.projectId != null
  );

  const isClientLinkLevel = useSelector(
    (state) => state.auth?.me?.clientId != null
  );

  return {
    isDemo,
    isAdmin,
    isTechnician,
    isDemoUser: isDemo && !isAdmin,
    isVisitor,
    isStateLinkLevel,
    isProjectLinkLevel,
    isClientLinkLevel,
  };
};

export default useAuthInfo;
