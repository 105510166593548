import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RecordType } from "../../../api/consts";
import { DimensionType } from "../../../api/dimensionType";
import {
  useCreateRecordMutation,
  useDeleteRecordMutation,
  useLazyGetRecordQuery,
  useUpdateRecordMutation,
} from "../../../api/managementApi";
import { namespaces } from "../../../consts/i18n";
import useAuthInfo from "../../../hooks/useAuthInfo";
import useRecordPrinting from "../../../hooks/useRecordPrinting";
import { addMessage } from "../../../redux/messages/messagesReducer";
import {
  addNonStoredRecord,
  addToEditableRecords,
  clearRecordChanges,
  deselectRecord,
  hideRecord,
  removeFromEditableRecords,
  removeNonStoredRecord,
  selectRecord,
  showRecord,
} from "../../../redux/records/recordsReducer";
import {
  computeArea,
  flattenPoints,
  getCenterPoint,
  isValidPolygon,
} from "../../../utils/geo";
import CreateRecordDialog from "../../dialogs/CreateRecordDialog";
import EditRecordDialog from "../../dialogs/EditRecordDialog";
import MessageDialog from "../../dialogs/MessageDialog";
import { RecordToPrint } from "../RecordToPrint";
import {
  ButtonsDelimiter,
  DeleteButton,
  DetailButton,
  ExportButton,
  SaveChangesButton,
  VisibilityButton
} from "./ActionButtons";
import ListItemHeader from "./ListItemHeader";
import ListItemWrapper from "./ListItemWrapper";
import { PointItem } from "./MeasureListItem";

const RecordListItem = ({ record, selected, onClick }) => {
  const { t } = useTranslation(namespaces.viewer);
  const dispatch = useDispatch();
  const [deleteRecord, { isLoading: isDeleting }] = useDeleteRecordMutation();
  const [createRecord] = useCreateRecordMutation();
  const [updateRecord] = useUpdateRecordMutation();
  const [getRecord] = useLazyGetRecordQuery();

  const { hidden, changes } = useSelector((state) => state.records);

  const { isDemoUser, isStateLinkLevel } = useAuthInfo();
  const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);


  const { printRef, isPrinting, handleExport, printData } =
    useRecordPrinting(record);

  const [isOpenEditRecordDialog, setIsOpenEditRecordDialog] =
    React.useState(false);

  const [isCreateRecordDialogOpen, setIsCreateRecordDialogOpen] =
    React.useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { project } = useSelector((state) => state.info);

  const [createRecordInitialData, setCreateRecordInitialData] = React.useState(
    {}
  );

  const handleDeleteClick = useCallback(() => {
    if (record.toBeStored === false) {
      dispatch(removeNonStoredRecord(record.id));
    } else {
      deleteRecord({ id: record.id });
    }
  }, [record, deleteRecord, dispatch]);

  const handleStoreClick = useCallback(() => {
    if (record.toBeStored === false) {
      const changesForRecord = changes[record.id];

      setCreateRecordInitialData({
        measure: {
          name: record.name,
          type: record.measure_type,
          points: changesForRecord ? changesForRecord.points : record.points,
        },
        isFromRecord: true,
        record: {
          ...record,
        },
      });

      setIsCreateRecordDialogOpen(true);
      // createRecord({ ...record })
      //   .unwrap()
      //   .then((res) => {
      //     dispatch(removeNonStoredRecord(record.id));
      //   });
    } else {
      deleteRecord({ id: record.id })
        .unwrap()
        .then((res) => {
          dispatch(addNonStoredRecord(record));
        });
    }
  }, [record, createRecord, deleteRecord, dispatch, changes]);

  const handleSaveChangesClick = useCallback(async () => {
    if (record.toBeStored === false) {
      // create record
      handleStoreClick();
    } else {
      // update record
      const changesForRecord = changes[record.id];

      if (changesForRecord) {
        const { points } = changesForRecord;

        const response = await getRecord({ id: record.id });

        let area = null;
        if (response.data.type === RecordType.AREA) {
          if (isValidPolygon(points)) {
            area = computeArea(points);
          } else {
            dispatch(
              addMessage({
                type: "error",
                message: "Zadané body nevytváří platný polygon",
              })
            );
            return;
          }
        }

        const recent =
          area != null
            ? {
                ...response.data,
                extra: { ...response.data.extra, size: area },
                data: points,
              }
            : {
                ...response.data,
                data: points,
              };

        await updateRecord({
          id: record.id,
          ...recent,
        }).unwrap();

        dispatch(removeFromEditableRecords(record.id));
        dispatch(clearRecordChanges(record.id));
      }
    }
  }, [record, changes, dispatch, handleStoreClick]);

  const handleVisibilityClick = useCallback(() => {
    if (hidden.includes(record.id)) {
      dispatch(showRecord(record.id));
    } else {
      dispatch(hideRecord(record.id));
    }
  }, [record, hidden, dispatch]);

  const handleSelectedClick = useCallback(() => {
    dispatch(selectRecord(record.id));
  }, [record, dispatch]);

  const handleDeselectedClick = useCallback(() => {
    dispatch(deselectRecord(record.id));
  }, [record, dispatch]);

  const handleEditClick = useCallback(() => {
    setIsOpenEditRecordDialog(true);
  }, []);

  const handleSelectClick = () => {
    onClick();
  };

  const handleEditPolygonClick = useCallback(() => {
    dispatch(addToEditableRecords(record.id));
  }, [record, dispatch]);

  const displayedPoints = React.useMemo(() => {
    return [
      {
        key: "center_" + 1,
        label: "Střed",
        coordinate: getCenterPoint(flattenPoints(record?.points)),
        dimension: DimensionType.D2,
      },
    ];
  }, [record]);

  const hasChangesToSave = React.useMemo(() => {
    return record.toBeStored === false || (changes[record.id] ? true : false);
  }, [record, changes]);

  return (
    <ListItemWrapper
      header={
        <ListItemHeader
          isExpandable={true}
          expanded={selected}
          title={record.name}
          onClick={handleSelectClick}
        ></ListItemHeader>
      }
      actionButtons={[
        canEdit && hasChangesToSave ? (
          <SaveChangesButton
            onClick={handleSaveChangesClick}
            disabled={false}
          />
        ) : null,
        // record.toBeStored === false ? (
        //   <StoreButton
        //     onClick={handleStoreClick}
        //     disabled={false}
        //     stored={record.toBeStored === false ? false : true}
        //   ></StoreButton>
        // ) : null,
        <ButtonsDelimiter />,
        <VisibilityButton
          onClick={handleVisibilityClick}
          disabled={false}
          visible={!hidden.includes(record.id)}
        />,
        record.toBeStored ? (
          <DetailButton onClick={handleEditClick} disabled={false} />
        ) : null,
        record.toBeStored ? (
          <ExportButton
            onClick={handleExport}
            disabled={isPrinting}
            isLoading={isPrinting}
          />
        ) : null,

        !canEdit && !record.toBeStored ? (
          <DeleteButton
            onClick={handleDeleteClick}
            disabled={false}
          />
        ) : null,
      ]}
      onSelected={handleSelectedClick}
      onDeselected={handleDeselectedClick}
    >
      {selected && displayedPoints.length > 0 && (
        <div className="border-t-2 border-secondary py-[5px]">
          {displayedPoints.map((point) => (
            <PointItem
              isLoading={point.isLoading}
              key={point.key || point.label + point.coordinate.toString}
              coordinate={point.coordinate}
              label={point.label}
              dimension={point.dimension}
              onEdit={canEdit && handleEditPolygonClick}
              onDelete={
                canEdit &&
                (() => {
                  setDeleteDialogOpen(true);
                })
              }
            />
          ))}
        </div>
      )}

      <EditRecordDialog
        isOpen={isOpenEditRecordDialog}
        onClose={() => {
          setIsOpenEditRecordDialog(false);
        }}
        recordId={record.id}
      />
      {isCreateRecordDialogOpen && (
        <CreateRecordDialog
          project={project}
          isOpen={isCreateRecordDialogOpen}
          initialData={createRecordInitialData}
          onClose={() => setIsCreateRecordDialogOpen(false)}
        />
      )}
      {/* absolute top-0 left-0 w-[800px] h-[1800px] */}
      <div className="hidden">
        <RecordToPrint
          ref={printRef}
          record={printData?.record || {}}
        />
      </div>
      <MessageDialog
        isOpen={deleteDialogOpen}
        title={t("deleteRecordDialog.title")}
        onCancel={() => setDeleteDialogOpen(false)}
        onSubmit={handleDeleteClick}
        cancelText={t("deleteRecordDialog.cancel")}
        submitText={t("deleteRecordDialog.delete")}
      />
    </ListItemWrapper>
  );
};

export default RecordListItem;
