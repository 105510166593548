import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useCreateTechnicianLinkQuery,
	useLinkQuery,
	useRegenerateLinkMutation,
	useRegenerateTechnicianLinkMutation,
} from "../../api/loginApi";
import { namespaces } from "../../consts/i18n";
import {
	createResultLinkFromDataAndToken,
	getObjectFromIds,
} from "../../utils/linkUtils";
import { isString } from "../../utils/stringUtils";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import DialogWrapper from "./DialogWrapper";
import MessageDialog from "./MessageDialog";

const ShareLinkDialog = ({
	isOpen,
	data,
	onClose,
	deactivateDisabled,
	disabled,
	isTechnicianLink = false,
}) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		onClose();
	};

	const { t } = useTranslation(namespaces.pages);
	const ids = getObjectFromIds(data);
	const { data: linkData, refetch, error: linkError } = useLinkQuery(ids);
	const { data: technicianLink, refetch: technicianLinkRefetch } =
		useCreateTechnicianLinkQuery({
			stateId: ids.stateId,
		});
	const [
		regenerateLink,
		{ isLoading: isRegeneratingLink, error: regenerateLinkError },
	] = useRegenerateLinkMutation();
	const [regenerateTechnicianLink] = useRegenerateTechnicianLinkMutation();

	const [isClipboardCopied, setIsClipboardCopied] = useState(false);

	useEffect(() => {
		setInputValue(t("shareLinkDialog.linkLoading"));
		setIsClipboardCopied(false);
		refetch();
	}, [isOpen]);

	const [inputValue, setInputValue] = useState(" ");

	useEffect(() => {
		if (isTechnicianLink && technicianLink?.token) {
			setInputValue(
				createResultLinkFromDataAndToken(data, technicianLink.token)
			);
			return;
		}
		if (linkData?.token && isString(linkData.token)) {
			setInputValue(createResultLinkFromDataAndToken(data, linkData.token));
		}
	}, [linkData, setInputValue, data, isTechnicianLink, technicianLink]);

	const handleClose = (e) => {
		e.preventDefault();
		onClose();
	};

	const handleRequestDeactivate = (e) => {
		e.preventDefault();
		setIsDeactivateWarningDialogOpen(true);
	};

	const handleDeactivate = async () => {
		if (isTechnicianLink) {
			await regenerateTechnicianLink(ids);
			technicianLinkRefetch();
		} else {
			// handles normal folder share & refetch
			await regenerateLink(ids);
			await refetch();
		}
		setIsDeactivateWarningDialogOpen(false);
		setIsClipboardCopied(false);
	};

	const handleCopyLink = (e) => {
		e.preventDefault();
		navigator.clipboard.writeText(inputValue);
		setIsClipboardCopied(true);
	};

	const [isDeactivateWarningDialogOpen, setIsDeactivateWarningDialogOpen] =
		useState(false);

	const error = linkError || regenerateLinkError;

	return isOpen ? (
		<DialogWrapper onClick={handleClose}>
			{/* {error && <Toast message={error.error} type="error"></Toast>} */}
			<div
				className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
				onClick={(e) => e.stopPropagation()}
			>
				<CloseButton onClick={handleClose}></CloseButton>
				<div className="flex flex-col gap-y-[39px]">
					<div>
						<h3 className="text-xxxl font-bold text-center px-[26px] text-ellipsis overflow-hidden">
							{isTechnicianLink
								? "Sdílení odkazu pro servisní techniky"
								: `${t("shareLinkDialog.title")} ${data.name}`}
						</h3>
					</div>

					<div>
						<div className="relative">
							<input
								value={disabled ? "http://localhost:3000/link/1" : inputValue}
								disabled={true}
								className={
									"rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1 text-[#B3B3B3] pr-[56px]" +
									(disabled ? " cursor-not-allowed blur-sm" : "")
								}
								id="name"
								type="text"
								placeholder=""
							/>
							<button
								className="absolute right-0 top-0 h-full w-[56px] flex justify-center items-center active:opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
								onClick={handleCopyLink}
								disabled={disabled}
							>
								<img src="/images/copy.svg" alt="copy" className="" />
							</button>
						</div>
						{isClipboardCopied && (
							<div className="mx-[10px] my-[5px] text-sm text-center">
								{t("shareLinkDialog.linkCopiedToClipboard")}
							</div>
						)}
					</div>

					<TextButton
						className={"h-[56px]"}
						secondary
						label={t("shareLinkDialog.deactivate")}
						onClick={handleRequestDeactivate}
						disabled={deactivateDisabled}
					/>

					<div className="flex flex-row items-center justify-center grow-0">
						<div>
							<TextButton
								disabled={!inputValue}
								className={"h-[56px]"}
								label={t("shareLinkDialog.close")}
								onClick={handleSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
			<MessageDialog
				isSubmitLoading={isRegeneratingLink}
				isOpen={isDeactivateWarningDialogOpen}
				title={t("shareLinkDialog.deactivateWarning")}
				submitText={t("shareLinkDialog.submitDeactivate")}
				cancelText={t("shareLinkDialog.notDeactivate")}
				onCancel={() => setIsDeactivateWarningDialogOpen(false)}
				onSubmit={handleDeactivate}
			/>
		</DialogWrapper>
	) : null;
};

export default ShareLinkDialog;
