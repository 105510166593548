import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DialogWrapper from "./DialogWrapper";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import { namespaces } from "../../consts/i18n";
import CircleButton from "../buttons/CircleButton";
import LoadingBar from "../LoadingBar";
import { toReadableDate } from "../../utils/date";
import { useDropzone } from "react-dropzone";
import uploadApi from "../../api/uploadApi";
import TextInput from "../inputs/TextInput";
import { stateNameMaxLength, stateNamePattern } from "../../utils/fsUtils";
import { useDispatch } from "react-redux";
import { addMessage } from "../../redux/messages/messagesReducer";
import {
  useLazyConvertPotreeQuery,
  useLazyConvertTiffQuery,
} from "../../api/computeApi";
import MessageDialog from "./MessageDialog";
import { isLasFile, isTifFile } from "../../utils/fsUtils";

const GrayWrapper = ({ children, className, ...otherProps }) => {
  return (
    <div
      className={`bg-bg1 rounded-lg pt-[24px] pb-[17px] py-[25px] flex flex-col ${
        className || ""
      }`}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export const DropFilesComponent = ({
  files,
  onFilesChange,
  removeFileEnabled,
  dropAreaText,
  lightTheme,
  disabled,
}) => {

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      if (files.length > 0) {
        onFilesChange([...files, ...acceptedFiles]);
      } else {
        onFilesChange([...acceptedFiles]);
      }
    },
    [files]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const wrapperProps = disabled
    ? {
        className:
          "justify-center items-center " + (lightTheme ? "bg-white" : "bg-bg1"),
      }
    : getRootProps({
        className:
          "justify-center items-center " + (lightTheme ? "bg-white" : "bg-bg1"),
      });

  const textColor = lightTheme ? "text-[#a1a1a1]" : "text-black-700";

  return (
    <React.Fragment>
      <GrayWrapper {...wrapperProps}>
        <CircleButton
          icon="/images/plus_white.svg"
          primary
          disabled={disabled}
          iconSize="12px"
          onClick={(e) => {}}
        ></CircleButton>
        <span
          className={`${
            lightTheme ? "text-500 font-medium" : "text-700 font-bold"
          } ${textColor} text-sm p-[10px]`}
        >
          {dropAreaText}
        </span>
        {!disabled && <input {...getInputProps()} />}
      </GrayWrapper>

      {files.length > 0 && (
        <GrayWrapper
          className={
            "justify-left items-left w-full py-[5px] pb-[4px] pt-[4px] mt-[5px]" +
            (lightTheme ? " bg-white" : " bg-bg1")
          }
        >
          <div className="flex flex-col gap-y-[5px] px-[25px] max-h-[200px] my-[5px] overflow-y-auto">
            {files.map((file, id) => (
              <div
                key={file.name + id}
                className="flex flex-row justify-between"
              >
                <span
                  className={`font-bold text-700 ${textColor} text-sm py-[4px] max-w-[200px] overflow-hidden text-ellipsis`}
                >
                  {file.name}
                </span>
                {removeFileEnabled ? null : (
                  <CircleButton
                    icon="/images/sidebar_delete.svg"
                    iconSize="14px"
                    onClick={(e) => {
                      e.stopPropagation();

                      onFilesChange(files.filter((f, i) => i !== id));
                    }}
                  ></CircleButton>
                )}
              </div>
            ))}
          </div>
        </GrayWrapper>
      )}
    </React.Fragment>
  );
};

const UploadMeasureDialog = ({
  isOpen,
  onClose,
  onSubmit,
  disabled,
  createProjectStateDraft,
  deleteProjectStateDraft,
}) => {
  const { t } = useTranslation(namespaces.pages);

  const [name, setName] = React.useState(
    toReadableDate(new Date().toISOString())
  );

  const dispatch = useDispatch();

  const [nameValid, setNameValid] = React.useState(true);

  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState("");

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [cancelConvertDialogOpen, setCancelConvertDialogOpen] =
    React.useState(false);

  const [files, setFiles] = React.useState([
    // "Lhotka_mereni.tif",
    // "Lhotka_3d.las",
  ]);

  const abortController = React.useRef(null);

  const [
    potreeConversionTrigger,
    { isLoading: isConvertingPotree, data: potreeConversionData },
  ] = useLazyConvertPotreeQuery();

  const [geoTiffConversionTrigger, { isLoading: isConvertingGeoTiff }] =
    useLazyConvertTiffQuery();

  useEffect(() => {
    if (uploadError)
      dispatch(addMessage({ type: "error", message: uploadError }));
  }, [uploadError]);

  // rewrite to useCallback
  const handleSubmit = useCallback(
    async (e) => {
      let draft = null;
      try {
        setIsUploading(true);
        setUploadError("");

        draft = await createProjectStateDraft({
          name,
        });

        await new Promise((resolve) => setTimeout(resolve, 100));

        // upload files
        const controller = new AbortController();
        abortController.current = controller;
        // const uploadedFiles = await uploadApi.getFiles(draft.id);

        const res = await uploadApi.uploadFiles(draft.id, files, {
          onUploadProgress: ({ progress }) => {
            setUploadProgress(progress);
          },
          abortSignal: controller.signal,
        });

        if (res.data.length >= 3) {
          // convert potree
          const lasFile = res.data.find((f) => isLasFile(f));
          if (!lasFile?.id) throw new Error("No las file found");

          const potreeData = await potreeConversionTrigger(
            lasFile.id,
            false
          ).unwrap();

          // convert tif to geotiff
          const tifFile = res.data.find((f) => isTifFile(f));
          if (!tifFile?.id) throw new Error("No tif file found");

          const geoTiffData = await geoTiffConversionTrigger(
            tifFile.id,
            false
          ).unwrap();
          
          setIsUploading(false);

          onSubmit({
            name,
            id: draft.id,
          });
        } else {
        }
      } catch (e) {
        console.log(e);
        if (e.status > 300) {
          setUploadError(e.status.toString());
        } else {
          setUploadError(e.message);
        }
        if (draft?.id) await deleteProjectStateDraft(draft.id);
      } finally {
        setIsUploading(false);
      }

      e.preventDefault();
    },
    [name, onSubmit, files]
  );

  useEffect(() => {
    if (isOpen) {
      setName(toReadableDate(new Date().toISOString()));
      setFiles([]);
      setUploadProgress(0);
      setIsUploading(false);
      setFilesError("");
      setUploadError("");
      setCancelConvertDialogOpen(false);
      setCancelDialogOpen(false);
    }
  }, [isOpen]);

  const handleCancel = (e) => {
    e.preventDefault();
    if (isUploading) {
      setCancelDialogOpen(true);
    } else if (isConvertingPotree || isConvertingGeoTiff) {
      setCancelConvertDialogOpen(true);
    } else onClose();
    //onClose();
  };

  const [filesError, setFilesError] = React.useState("");

  useEffect(() => {
    const res = validateFiles(files);
    if (res.error) {
      setFilesError(res.error);
    } else {
      setFilesError("");
    }
  }, [files, setFilesError]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (disabled) return;

      setFiles([...files, ...acceptedFiles]);

      // const res = validateFiles([...files, ...acceptedFiles]);
      // if (res.error) {
      //   setFilesError(res.error);
      // } else {
      //   setFilesError("");
      // }
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accepting all for now
    // accept: {
    //   "text/plain": [".las", ".tfw"],
    //   "image/tiff": [".tif"],
    //   "image/tif": [".tif"],
    // },
  });

  const handleAbortUpload = () => {
    abortController.current?.abort();
    setCancelDialogOpen(false);
    onClose();
  };

  const handleCancelAbort = () => {
    setCancelDialogOpen(false);
    setCancelConvertDialogOpen(false);
  };

  const handleAbortConvert = () => {
    setCancelConvertDialogOpen(false);
    onClose();
  };

  // const error = uploadError;

  return isOpen ? (
    <DialogWrapper onClick={handleCancel}>
      {/* {error && <Toast message={error} type="error" />} */}
      <div
        className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={handleCancel}></CloseButton>
        <div className="flex flex-col gap-y-[30px] items-left justify-left">
          <div>
            <h3 className="text-xxxl font-bold text-center px-[26px]">
              {t("uploadMeasureDialog.title")}
            </h3>
          </div>

          {/* <div className="mb-[20px]">
            <label
              className="block text-left text-700 font-bold text-xs mb-2 text-primary"
              htmlFor="name"
            >
              {t("uploadMeasureDialog.name")}
            </label>
            <input
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="rounded-lg px-[14px] py-[14px] text-left w-full text-input-500 focus:outline-none focus:shadow-outline bg-bg1"
              id="name"
              type="text"
              placeholder=""
              value={name}
            />
          </div> */}

          <TextInput
            label={t("uploadMeasureDialog.name")}
            onChange={(val) => {
              setName(val);
            }}
            pattern={stateNamePattern}
            maxLength={stateNameMaxLength}
            defaultValue={name}
            disabled={isUploading || isConvertingGeoTiff || isConvertingPotree}
            onValidityChange={(valid) => {
              setNameValid(valid);
            }}
          />
          {!isUploading && !isConvertingGeoTiff && !isConvertingPotree && (
            <GrayWrapper
              {...getRootProps({
                className: "justify-center items-center",
              })}
            >
              <CircleButton
                icon="/images/plus_white.svg"
                primary
                iconSize="12px"
                onClick={(e) => {}}
                disabled={disabled}
              ></CircleButton>
              <span className="font-bold text-black-700 text-sm p-[10px]">
                {t("uploadMeasureDialog.uploadInfo")}
              </span>
              <input {...getInputProps()} />
            </GrayWrapper>
          )}
          {files.length > 0 && (
            <GrayWrapper className={"justify-left items-left w-full"}>
              <div className="flex flex-col gap-y-[10px] px-[25px]">
                {files.map((file, id) => (
                  <div
                    key={file.name + id}
                    className="flex flex-row justify-between"
                  >
                    <span className="font-bold text-black-700 text-sm py-[4px]">
                      {file.name}
                    </span>
                    {isUploading ? null : (
                      <CircleButton
                        disabled={disabled}
                        icon="/images/sidebar_delete.svg"
                        iconSize="14px"
                        onClick={(e) => {
                          e.stopPropagation();

                          setFiles(files.filter((f, i) => i !== id));
                        }}
                      ></CircleButton>
                    )}
                  </div>
                ))}
              </div>
            </GrayWrapper>
          )}

          {filesError && (
            <div className="text-red-500 text-xs mt-[-20px]">
              {t("uploadMeasureDialog." + filesError)}
            </div>
          )}

          {isUploading && (
            <LoadingBar progress={uploadProgress * 100}></LoadingBar>
          )}

          {isConvertingPotree && (
            <div className="flex flex-col gap-y-[10px] px-[25px]">
              <div className="flex flex-row justify-between">
                <span className="font-bold text-black-700 text-sm py-[4px]">
                  {t("uploadMeasureDialog.convertingPotree")}
                </span>
              </div>
            </div>
          )}

          {isConvertingGeoTiff && (
            <div className="flex flex-col gap-y-[10px] px-[25px]">
              <div className="flex flex-row justify-between">
                <span className="font-bold text-black-700 text-sm py-[4px]">
                  {t("uploadMeasureDialog.convertingGeoTiff")}
                </span>
              </div>
            </div>
          )}

          <div className="flex items-center justify-center">
            <div>
              <TextButton
                disabled={
                  isUploading ||
                  isConvertingGeoTiff ||
                  isConvertingPotree ||
                  filesError !== "" ||
                  files.length === 0 ||
                  !nameValid ||
                  name === ""
                }
                isLoading={isUploading}
                className={"h-[56px]"}
                label={t("uploadMeasureDialog.ok")}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
      <MessageDialog
        isOpen={cancelDialogOpen}
        title={t("uploadMeasureDialog.cancelUploadDialog.title")}
        submitText={t("uploadMeasureDialog.cancelUploadDialog.ok")}
        cancelText={t("uploadMeasureDialog.cancelUploadDialog.cancel")}
        onSubmit={handleAbortUpload}
        onCancel={handleCancelAbort}
      ></MessageDialog>
      <MessageDialog
        isOpen={cancelConvertDialogOpen}
        title={t("uploadMeasureDialog.cancelConvertDialog.title")}
        submitText={t("uploadMeasureDialog.cancelConvertDialog.ok")}
        cancelText={t("uploadMeasureDialog.cancelConvertDialog.cancel")}
        onSubmit={handleAbortConvert}
        onCancel={handleCancelAbort}
      ></MessageDialog>
    </DialogWrapper>
  ) : null;
};

const validateFiles = (files) => {
  const validFiles = files.filter((file) => {
    // check if file has .las  or .tif extension or .tfw
    return (
      file.name.toLowerCase().endsWith(".las") ||
      file.name.toLowerCase().endsWith(".tif") ||
      file.name.toLowerCase().endsWith(".tfw")
    );
  });
  // check if there are 3 files and one of them is .las and the other is .tif and .tfw

  if (!validFiles.some((file) => file.name.toLowerCase().endsWith(".las"))) {
    return {
      error: "errorNoLas",
      isValid: false,
    };
  }

  if (!validFiles.some((file) => file.name.toLowerCase().endsWith(".tif"))) {
    return {
      error: "errorNoTif",
      isValid: false,
    };
  }

  if (!validFiles.some((file) => file.name.toLowerCase().endsWith(".tfw"))) {
    return {
      error: "errorNoTfw",
      isValid: false,
    };
  }

  // check there is only one .las file
  if (
    validFiles.filter((file) => file.name.toLowerCase().endsWith(".las"))
      .length > 1
  ) {
    return {
      error: "errorLasCount",
      isValid: false,
    };
  }

  // check there is only one .tif file
  if (
    validFiles.filter((file) => file.name.toLowerCase().endsWith(".tif"))
      .length > 1
  ) {
    return {
      error: "errorTifCount",
      isValid: false,
    };
  }
  // check there is only one .tfw file

  if (
    validFiles.filter((file) => file.name.toLowerCase().endsWith(".tfw"))
      .length > 1
  ) {
    return {
      error: "errorTfwCount",
      isValid: false,
    };
  }

  if (files.length < 3) {
    return {
      error: "errorFilesCount",
      isValid: false,
    };
  }

  //tiff and tfw must have the same name
  const tiffFile = validFiles.find((file) =>
    file.name.toLowerCase().endsWith(".tif")
  );

  const tfwFile = validFiles.find((file) =>
    file.name.toLowerCase().endsWith(".tfw")
  );

  if (tiffFile.name.split(".")[0] !== tfwFile.name.split(".")[0]) {
    return {
      error: "errorTiffTfwName",
      isValid: false,
    };
  }

  return {
    error: "",
    isValid: true,
  };
};

export default UploadMeasureDialog;
