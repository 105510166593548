import React from "react";
import { useGetTechnicianLogDetailsQuery } from "../../api/technicianLogApi";

export const TechnicianLogDetails = ({ technicianLogId }) => {
	const { data: logData, isLoading } = useGetTechnicianLogDetailsQuery({
		technicianLogId,
	});

	if (isLoading) {
		return <p>Loading...</p>;
	}

	return (
		<div className="grid grid-cols-5 mt-1 w-full font-medium text-xs">
			<p>{new Date(logData.date).toLocaleDateString()}</p>
			<p>{logData.technicianName}</p>
			<p>{logData.action}</p>
			<p>{logData.note || "-"}</p>
			<p>{logData.files.length || "-"}</p>
		</div>
	);
};
