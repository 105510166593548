import debounce from "lodash/debounce";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	pointsArrayOmitZ,
	useLazyGetLasExtremePointsQuery,
} from "../api/computeApi";
import { DimensionType } from "../api/dimensionType";
import uploadApi from "../api/uploadApi";
import LoadingOverlay from "../components/LoadingOverlay";
import PotreeViewer from "../components/viewer/PotreeViewer";
import Sidebar from "../components/viewer/sidebar/Sidebar";
import { TechnicianNameOverlay } from "../components/viewer/TechnicianNameOverlay";
import TopBar from "../components/viewer/TopBar";
import { namespaces } from "../consts/i18n";
import LineDistance3D from "../features/measures/lineDistance3D";
import Point3D from "../features/measures/point3D";
import PolygonVolume3D from "../features/measures/polygonVolume3D";
import useGetMeasures from "../hooks/useGetMeasures";
import useGetProjectStateAdditionals, {
	extractLasFileId,
} from "../hooks/useGetProjectStateAdditionals";
import useLinkAccessCheck from "../hooks/useLinkAccessCheck";
import usePotreeMeasurements from "../hooks/usePotreeMeasurements";
import usePotreeMeasurementsListeners from "../hooks/usePotreeMeasurementsListeners";
import usePotreeViewer from "../hooks/usePotreeViewer";
import useSetViewerTitle from "../hooks/useSetViewerTitle";
import {
	getUniqueName,
	setMeasureMode,
} from "../redux/measures/measuresReducer";
import {
	addNonStoredMeasure,
	clearMeasures,
	getKeyForMinMax,
	updateNonStoredMeasure,
} from "../redux/measures2/measures2Reducer";
import { toolModeType } from "../redux/pointCloudViewer/pointCloudViewerReducer";
import uuid from "../utils/uuid";
import NotFoundPage from "./NotFoundPage";

const serializeMeasure = (measure) =>
	(measure.measureType === "distance" &&
		LineDistance3D.fromPotreeMeasure(measure).serialize()) ||
	(measure.measureType === "volume" &&
		PolygonVolume3D.fromPotreeMeasure(measure).serialize()) ||
	(measure.measureType === "point" &&
		Point3D.fromPotreeMeasure(measure).serialize());

function createMeasure3D(measure, viewer) {
	let measure3D;

	if (measure.type === "point") {
		measure3D = new Point3D(measure.points, measure.name, viewer);
	}

	if (measure.type === "distance") {
		measure3D = new LineDistance3D(measure.points, measure.name, viewer);
	}

	if (measure.type === "volume") {
		measure3D = new PolygonVolume3D(measure.points, measure.name, viewer);
	}

	if (!measure3D) {
		return;
	}

	measure3D.potreeMeasure.toBeStored = measure.toBeStored;
	measure3D.potreeMeasure.dbId = measure.dbId;
	return measure3D;
}

const PointCloudViewerPage = () => {
	const { projectId, clientId, stateId } = useParams();
	const { t } = useTranslation(namespaces.viewer);
	useLinkAccessCheck();

	const dispatch = useDispatch();
	const measurementsData = useSelector((state) => state.measures.data);
	const [pointCloudUrlError, setPointCloudUrlError] = React.useState(false);

	const { hidden, selected, editable, minMax } = useSelector(
		(state) => state.measures2
	);

	const {
		initialMeasures,
		isLoadingMeasures,
		isFetchingMeasures,
		projectState,
	} = useGetProjectStateAdditionals({
		projectId,
		clientId,
		stateId,
		dimension: DimensionType.D3,
	});

	useSetViewerTitle({
		dimensionType: DimensionType.D3,
	});

	const { measures } = useGetMeasures({
		projectId,
		dimension: DimensionType.D3,
	});

	useEffect(() => {
		dispatch(setMeasureMode(DimensionType.D3));

		return () => {
			dispatch(clearMeasures());
		};
	}, []);

	const onAddMeasurement = useCallback(
		async (measure) => {
			// console.log(measure);
			// measure.setEditable(!measure.toBeStored);
			// const serialized = serializeMeasure(measure);
			// if (!serialized) return;
			// const isPoint = measure.measureType === "point";
			// serialized.isHiddenInSidebar =
			//   isPoint || measure.points?.length > 1 ? false : true;
			// dispatch(addMeasure(serialized));
		},
		[initialMeasures, projectState]
	);

	const onStopInsertingMeasurement = async (measure) => {
		measure.dbId = uuid();

		const serialized = serializeMeasure(measure);

		// console.log("onStopInsertingMeasurement", serialized);

		measurements.removeMeasurement(measure);
		if (measure.measureType === "volume") {
			if (measure.points.length < 3) {
				return;
			}
		}
		if (measure.measureType === "distance") {
			if (measure.points.length < 2) {
				return;
			}
		}
		if (measure.measureType === "point") {
			if (measure.points.length < 1) {
				return;
			}
		}

		dispatch(addNonStoredMeasure(serialized));
	};

	const onRemoveMeasurement = (measure) => {
		// dispatch(removeMeasure(measure.uuid));
	};

	const onChangeMeasurement = (measure) => {
		const serialized = serializeMeasure(measure);
		// console.log("onChangeMeasurement", serialized);
		// if (serialized) dispatch(changeMeasure(serialized));
		if (!serialized) return;
		if (!serialized.id) return;
		dispatch(
			updateNonStoredMeasure({ id: serialized.id, points: serialized.points })
		);
	};

	const changeMeasureDebounced = debounce(onChangeMeasurement, 300);

	usePotreeMeasurementsListeners({
		onAddMeasurement,
		onRemoveMeasurement,
		onStopInsertingMeasurement,
		onChangeMeasurement: changeMeasureDebounced, // prevent too many updates
	});

	const measurements = usePotreeMeasurements();
	// useEffect(() => {
	//   refetchMeasures();
	// }, []);
	const { viewer, loadPointCloud } = usePotreeViewer();

	const [computeExtremePoints] = useLazyGetLasExtremePointsQuery();

	const [isPointCloudUrlLoaded, setIssPointCloudUrlLoaded] =
		React.useState(false);

	useEffect(() => {
		if (!viewer) return;
		if (!projectState) return;

		const pointCloudUrl = uploadApi.getPotreeMetadataUrl(projectState);

		if (!pointCloudUrl) {
			setPointCloudUrlError(new Error("No pointcloud found"));
			return;
		}

		loadPointCloud(pointCloudUrl);
		setIssPointCloudUrlLoaded(true);

		return () => {};
	}, [viewer, projectState]);

	useEffect(() => {
		if (!viewer) return;
		if (!measures) return;
		// if(!measurements) return;

		measures?.forEach((measure) => {
			const measure3D = createMeasure3D(measure, viewer);
			if (!measure3D) {
				console.log("measure3D not created", measure);
				return;
			}
			measurements.addMeasurement(measure3D.potreeMeasure);
		});

		//console.log("measurements", measurements.getPotreeMeasurements());

		return () => {
			//console.log("clearing measurements");
			measurements.clearMeasurements();
		};
	}, [viewer, measures]);

	// trigger and show min max computation
	useEffect(() => {
		if (!viewer) return;
		if (!measures) return;

		const lasId = extractLasFileId(projectState);

		measures.forEach((measure) => {
			if (measure.type !== "volume") return;

			const measurement = viewer.scene.measurements.find(
				(m) => m.dbId === measure.dbId
			);

			const key = getKeyForMinMax(lasId, pointsArrayOmitZ(measure.points));
			const minMaxData = minMax[key];
			if (minMaxData) {
				// show?
				measurement.showMinMaxPoints(true, minMaxData.min, minMaxData.max);
			} else {
				measurement.showMinMaxPoints(false);
				if (measure.toBeStored) {
					computeExtremePoints({
						fileId: lasId,
						polygonPoints: pointsArrayOmitZ(measure.points),
						measureDbId: measure.dbId,
					});
				}
			}
		});
	}, [viewer, measures, minMax, projectState]);

	// hide select measure features
	useEffect(() => {
		if (!viewer) return;
		if (!hidden) return;
		if (!selected) return;

		// console.log("measurements", viewer.scene.measurements.length);
		viewer.scene.measurements.forEach((measure) => {
			// console.log(measure);
			const isHidden = hidden.includes(measure.dbId);
			measure.setVisible(!isHidden);
			const isSelected = selected.includes(measure.dbId);
			measure.hilite(isSelected);
			const isEditable = editable.includes(measure.dbId);
			measure.setEditable(isEditable);

			if (measure._measureType === "volume") {
				measure.showClipVolume(!isHidden);
			}
		});
	}, [viewer, hidden, selected, editable, measures]);

	if (!projectId) {
		return <NotFoundPage></NotFoundPage>;
	}

	const isLoading =
		isLoadingMeasures || isFetchingMeasures || !isPointCloudUrlLoaded;

	return (
		<React.Fragment>
			<div className="flex flex-row h-full">
				<TechnicianNameOverlay />
				<div className="grow relative bg-gradient-radial from-[#FAFAFA] to-white">
					<LoadingOverlay visible={isLoading}>
						<PotreeViewer />
					</LoadingOverlay>
					<TopBar
						onCreateMeasure={({ type }) => {
							const nameFromType = {
								[toolModeType.POINT]: t("defaultMeasureName.point"),
								[toolModeType.DISTANCE]: t("defaultMeasureName.distance"),
								[toolModeType.VOLUME]: t("defaultMeasureName.volume"),
							}[type];

							const funcFromType = {
								[toolModeType.POINT]: measurements.startPointMeasurement,
								[toolModeType.DISTANCE]: measurements.startDistanceMeasurement,
								[toolModeType.VOLUME]: measurements.startVolumeMeasurement,
							}[type];

							const uniqueName = getUniqueName(nameFromType, measures);
							funcFromType({ name: uniqueName });
						}}
					/>
				</div>
				{/* {viewer != null && <Toolbar></Toolbar>} */}
				<div className="flex-none w-[460px]">
					<Sidebar
						dimensionType={DimensionType.D3}
						onCreateMeasure={(name, type) => {
							const funcFromType = {
								[toolModeType.POINT]: measurements.startPointMeasurement,
								[toolModeType.DISTANCE]: measurements.startDistanceMeasurement,
								[toolModeType.VOLUME]: measurements.startVolumeMeasurement,
							}[type];

							const uniqueName = getUniqueName(name, measures);
							funcFromType({ name: uniqueName });
						}}
					></Sidebar>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PointCloudViewerPage;
