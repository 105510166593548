import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import CircleButton from "./buttons/CircleButton";
import PreviewFileDialog from "./dialogs/PreviewFileDialog";

export function UploadFilesSection({ onFilesChange, files }) {
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length === 0) return;
			if (files.length > 0) {
				onFilesChange([...files, ...acceptedFiles]);
			} else {
				onFilesChange([...acceptedFiles]);
			}
		},
		[files]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
	});

	return (
		<div className="flex flex-row gap-8">
			{files.length > 0 && (
				<div className="w-full">
					<span className="text-primary font-bold text-xs">
						Přiložené soubory (foto, reporty)
					</span>
					<div className="mt-1 min-h-full bg-white rounded-lg shadow-sm py-4 px-2">
						<div className="flex flex-col gap-2 max-h-[200px] my-[5px] overflow-y-auto">
							{files.map((file, id) => (
								<div
									key={file.name + id}
									className="flex flex-row justify-between items-center"
								>
									<span
										className={`text-700 text-zinc-500 pl-3 text-sm max-w-[200px] overflow-hidden text-ellipsis`}
									>
										{file.name}
									</span>
									<div>
										<CircleButton
											primary
											className="py-2 px-2 mr-2"
											icon="/images/preview.svg"
											iconSize="14px"
											onClick={(e) => {
												e.preventDefault();

												setIsPreviewOpen(true);
											}}
											//TODO: 🚨 add to translations
											title="File Preview"
										/>
										<CircleButton
											icon="/images/sidebar_delete.svg"
											className="py-2 px-2"
											iconSize="14px"
											onClick={(e) => {
												e.preventDefault();
												onFilesChange(files.filter((f, i) => i !== id));
											}}
										/>
									</div>
									<PreviewFileDialog
										isOpen={isPreviewOpen}
										onClose={() => setIsPreviewOpen(false)}
										file={file}
										isLocalFile
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			<div {...getRootProps()} className="w-full cursor-pointer">
				<span className="text-primary font-bold text-xs">
					{/* // TODO: 🚨 add to translations */}
					Nahrát další soubory
				</span>
				<div className="min-h-full flex flex-col justify-center items-center gap-2 mt-1 py-1 px-4 text-sm rounded-lg shadow-sm text-center bg-white text-zinc-800 font-bold">
					<input className="hidden" {...getInputProps()} />
					<div className="bg-primary p-2 rounded-full w-fit">
						<img
							className="hue-rotate-180 sepia contrast-200 w-3 h-3"
							src="/images/bullet_plus.svg"
							alt=""
							height={3}
							width={3}
						/>
					</div>
					{/* // TODO: 🚨 add to translations */}
					<span className="text-xs text-zinc-400 font-normal max-w-24">
						Přetáhnout a nahrát další soubory
					</span>
				</div>
			</div>
		</div>
	);
}
