import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { computeApi } from "../api/computeApi";
import { faultApi } from "../api/faultsApi";
import { loginApi } from "../api/loginApi";
import { managementApi } from "../api/managementApi";
import { technicianLogApi } from "../api/technicianLogApi";
import authReducer from "./auth/authReducer";
import infoReducer from "./info/infoReducer";
import mapViewerReducer from "./mapViewer/mapViewerReducer";
import measuresReducer from "./measures/measuresReducer";
import measures2Reducer from "./measures2/measures2Reducer";
import messageReducer from "./messages/messagesReducer";
import panelReducer from "./panel/panelSlice";
import pointCloudViewerReducer from "./pointCloudViewer/pointCloudViewerReducer";
import recordsReducer from "./records/recordsReducer";
import technicianReducer from "./technician/technicianSlice";
import viewerReducer from "./viewer/viewerReducer";

export const listenerMiddleware = createListenerMiddleware();

// create sentry enhancer
const sentryEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
});

export const store = configureStore({
	reducer: {
		panel: panelReducer,
		technician: technicianReducer,
		auth: authReducer,
		info: infoReducer,
		pointCloudViewer: pointCloudViewerReducer, // TODO: remove
		mapViewer: mapViewerReducer, // TODO: remove
		viewer: viewerReducer,
		measures: measuresReducer,
		measures2: measures2Reducer,
		records: recordsReducer,
		messages: messageReducer,
		[loginApi.reducerPath]: loginApi.reducer,
		[managementApi.reducerPath]: managementApi.reducer,
		[computeApi.reducerPath]: computeApi.reducer,
		[faultApi.reducerPath]: faultApi.reducer,
		[technicianLogApi.reducerPath]: technicianLogApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.prepend(listenerMiddleware.middleware)
			.concat(loginApi.middleware)
			.concat(managementApi.middleware)
			.concat(computeApi.middleware)
			.concat(faultApi.middleware)
			.concat(technicianLogApi.middleware),
	enhancers: [sentryEnhancer],
});

setupListeners(store.dispatch);
