import React, { useCallback, useEffect, useRef } from "react";
import uploadApi from "../api/uploadApi";

export const useUploadTechnicianLogPhotos = () => {
	const [isUploading, setIsUploading] = React.useState(false);
	const [uploadProgress, setUploadProgress] = React.useState(0);
	const [uploadError, setUploadError] = React.useState(null);

	const uploadAbortController = useRef(null);

	const uploadTechnicianLogPhotos = async (technicianLogId, fileToUpload) => {
		setIsUploading(true);
		try {
			const controller = new AbortController();
			uploadAbortController.current = controller;

			const res = await uploadApi.uploadTechnicianLogPhotos(
				technicianLogId,
				fileToUpload,
				{
					onUploadProgress: ({ progress }) => {
						setUploadProgress(progress);
					},
					abortSignal: controller.signal,
				}
			);

			setUploadProgress(0);

			setIsUploading(false);
			return res;
		} catch (e) {
			setUploadError(e.message);
			setIsUploading(false);
			return null;
		}
	};

	const clearUploadError = useCallback(() => {
		setUploadError(null);
	}, []);

	useEffect(() => {
		return () => {
			if (uploadAbortController.current) {
				uploadAbortController.current.abort();
			}
		};
	}, []);

	useEffect(() => {
		if (uploadError) {
			setTimeout(() => {
				setUploadError(null);
			}, 5000);
		}
	}, [uploadError]);

	return {
		uploadTechnicianLogPhotos,
		isUploading,
		uploadProgress,
		uploadError,
		clearUploadError,
		uploadAbortController: uploadAbortController.current,
	};
};
