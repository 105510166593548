import React from "react";
import { useGetFaultDetailsQuery } from "../../api/faultsApi";
import { TechnicianLogDetails } from "./TechnicianLogDetails";

export const FaultDetails = ({ faultId }) => {
	const { data: fault, isLoading } = useGetFaultDetailsQuery(
		{ faultId },
		{
			refetchOnMountOrArgChange: true,
		}
	);

	if (isLoading) {
		return <p>Loading...</p>;
	}

	return fault.logs.map((log) => (
		<TechnicianLogDetails key={log.id} technicianLogId={log.id} />
	));
};
