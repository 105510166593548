import React from "react";
// https://github.com/wojtekmaj/react-calendar
import Calendar from "react-calendar";
// TODO: not loading before component mounts, so the styles are not loaded properly
import "./Calendar.css";

/** @param {{styles: string}} props */
export const CalendarViewer = ({ styles }) => {
	return (
		<div className={`${styles} mt-8 max-w-xs`}>
			<Calendar tileClassName="!bg-neutral-100 rounded-md" />
		</div>
	);
};
