/**
 * @param { string} query - The query to search for in the string
 * @param { string} entry - The entry to compare the query to
 * @returns boolean
 */
export function poorMansFuzzySearch(query, entry) {
	const normalizedQuery = query.trim().toLowerCase();
	const normalizedEntry = entry.trim().toLowerCase();

	if (normalizedEntry.includes(normalizedQuery)) return true; // Direct match case

	let score = 0;
	let lastIndex = -1;

	for (let char of normalizedQuery) {
		let index = normalizedEntry.indexOf(char, lastIndex + 1);
		if (index === -1) return false; // Character not found in the required order
		score += index - lastIndex; // Increase score based on distance from the last match
		lastIndex = index;
	}

	return score < normalizedEntry.length; // true if the score is a close match
}
