import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RecordType } from "../../../api/consts";
import { DimensionType } from "../../../api/dimensionType";
import { namespaces } from "../../../consts/i18n";
import useGetProjectRecords from "../../../hooks/useGetRecords";
import RecordListItem from "./RecordListItem";
// get kv array of sizes from
// {"areaSum":2519.257873535156,"areaSizes":{"Roof":2201.648559570312,"Grass":317.6093139648438}}
const getAreaSizes = (extra) => {
	if (!extra) return null;
	const { areaSizes } = extra;
	if (!areaSizes) return null;
	return Object.entries(areaSizes);
};

const RecordList = () => {
	const { projectId } = useParams();

	const { t } = useTranslation(namespaces.viewer);

	const { records, extra } = useGetProjectRecords({
		projectId,
		dimension: DimensionType.D2,
	});

	useEffect(() => {
		setOnlyRecords(records.filter((r) => r.type !== RecordType.SOLAR_PANEL));
	}, [records]);

	const [onlyRecords, setOnlyRecords] = useState([]);

	const [selected, setSelected] = React.useState(null);

	const sumItems = getAreaSizes(extra);

	return (
		<div className="flex flex-col gap-[10px] overflow-auto">
			{onlyRecords?.map((record) => (
				<RecordListItem
					key={record.id}
					record={record}
					selected={selected?.id === record.id}
					onClick={() => {
						if (selected === record) setSelected(null);
						else setSelected(record);
					}}
				/>
			))}

			{sumItems && (
				<div className="flex flex-col gap-[10px]">
					{/*divider*/}
					<div className="h-[1px] bg-white my-[10px]"></div>
					{sumItems?.map(([name, size]) => (
						<div
							key={name}
							className="flex justify-between items-center hover:shadow-lg bg-white rounded-lg px-[20px] h-[40px]"
						>
							<div className="text-black font-bold text-700 text-[12px]">
								{t(`sidebar.sum.${name}`)}
							</div>
							<div className="text-black font-bold text-700 text-[12px]">
								{size.toFixed(2)} m²
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default RecordList;
