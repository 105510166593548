import { createSlice } from "@reduxjs/toolkit";
import { computeApi } from "../../api/computeApi";
import { loginApi } from "../../api/loginApi";
import { managementApi } from "../../api/managementApi";

const createError = (message) => {
	if (!message) return null;

	let msg = message;
	if (typeof message === "object" && message.message) {
		msg = message.message;
	} else if (typeof message === "object" && message.error) {
		msg = message.error;
	} else if (typeof message === "object") {
		if (message.status) {
			msg = JSON.stringify(message.status);
		} else {
			msg = JSON.stringify(message);
		}
	}

	let status = undefined;
	if (typeof message === "object") {
		status = message.status;
	}

	let source = undefined;
	if (typeof message === "object") {
		source = message.source;
	}

	// console.log(msg);

	// msg possibly contains `"` which will break the toast
	// so we need to escape them, best way is to use JSON.stringify
	// msg = JSON.stringify(msg);

	msg = msg.replace(/`/g, "");

	return {
		id: Date.now(),
		text: msg,
		status: status,
		type: "error",
		source: source,
	};
};

export const initialState = {
	data: [], // success messages
};
let msgI = 0;
export const messages = createSlice({
	name: "messages",
	initialState,
	reducers: {
		removeMessage: (state, { payload }) => {
			state.data = state.data.filter((m) => m.id !== payload);
		},
		addMessage: (state, { payload }) => {
			if (!payload) return;
			if (payload.type === "error") {
				const error = createError(payload);
				state.data.push(error);
			} else if (payload.type === "success") {
				state.data.push({
					id: msgI++,
					text: payload.text,
					type: "success",
				});
			}
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			managementApi.endpoints.createClient.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.clientSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.createProject.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.projectSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.createProjectState.matchFulfilled,
			(state, { payload }) => {
				// state.data.push({
				//   id: msgI++,
				//   text: "toasts.projectStateSuccess",
				//   type: "success",
				// });
			}
		);
		builder.addMatcher(
			managementApi.endpoints.deleteClient.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.clientDeleteSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.deleteProject.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.projectDeleteSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.deleteProjectState.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.projectStateDeleteSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.renameClient.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.clientRenameSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.renameProject.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.projectRenameSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.renameProjectState.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.projectStateRenameSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			managementApi.endpoints.renameClient.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.renameProject.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.renameProjectState.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.createClient.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.createProject.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.createProjectState.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.deleteClient.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.deleteProject.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.deleteProjectState.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.listClients.matchRejected,
			(state, action) => {
				const { payload } = action;
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.listProjects.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.listProjectStates.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.getClient.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.getProject.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.getProjectState.matchRejected,
			(state, { payload }) => {
				const error = createError(payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			managementApi.endpoints.saveMeasures.matchRejected,
			(state, action) => {
				const error = createError(action?.payload);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			loginApi.endpoints.regenerateLink.matchFulfilled,
			(state, { payload }) => {
				state.data.push({
					id: msgI++,
					text: "toasts.regenerateSuccess",
					type: "success",
				});
			}
		);
		builder.addMatcher(
			loginApi.endpoints.regenerateLink.matchRejected,
			(state, action) => {
				const error = createError(action?.payload?.error);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			computeApi.endpoints.convertPotree.matchRejected,
			(state, action) => {
				const error = createError(
					action?.payload?.data.error || action?.payload?.error
				);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			computeApi.endpoints.convertTiff.matchRejected,
			(state, action) => {
				const error = createError(
					action?.payload?.data.error || action?.payload?.error
				);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			computeApi.endpoints.compareVolume.matchRejected,
			(state, action) => {
				const error = createError(
					action?.payload?.data.error || action?.payload?.error
				);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			computeApi.endpoints.computeVolume.matchRejected,
			(state, action) => {
				// console.log(action);
				const error = createError(
					action?.payload?.data.error || action?.payload?.error
				);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			computeApi.endpoints.getLasExtremePoints.matchRejected,
			(state, action) => {
				// console.log(action);
				const error = createError(
					action?.payload?.data.error || action?.payload?.error
				);
				if (error) state.data.push(error);
			}
		);
		builder.addMatcher(
			// all other rejected actions
			(action) => {
				return action.type.endsWith("/rejected");
			},
			(state, action) => {
				// if (action.meta.arg.endpointName === "getMeasures") return;
				const error = createError(
					action?.payload?.data?.error ||
						action?.payload?.error ||
						action?.payload?.status.toString()
				);
				if (error) state.data.push(error);
			}
		);
	},
});

export const { removeMessage, addMessage } = messages.actions;

export default messages.reducer;
