import { useRef } from "react";
import { useOutsideAlerter } from "../hooks/useClickOutside";

/**
 * Component that executes a certain action when clicking outside of it
 * @param {{children: React.ReactNode, onClickOutside: () => void}} props
 * @returns {JSX.Element}
 */
export function OutsideAlerter({ children, onClickOutside }) {
	const wrapperRef = useRef(null);
	useOutsideAlerter({ ref: wrapperRef, onClickOutside });

	return <div ref={wrapperRef}>{children}</div>;
}
